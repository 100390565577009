//== .transition(0.4s);
.box-sizing(@box-sizing:border-box){
    -webkit-box-sizing: @box-sizing;
    -moz-box-sizing: @box-sizing;
    box-sizing: @box-sizing;
}

//== .clearfix();
.clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

//== .transition(0.4s);
.transition(@second:0){
    -webkit-transition: all @second ease 0s;
    transition: all @second ease 0s;
}

//== .border-radius(100%);
.border-radius(@radius:100%) {
    border-radius: @radius;
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    -ms-border-radius: @radius;
    -o-border-radius: @radius;
}

//== .square(30px) same height-width
.square(@width:@height, @height:@width) {
    width: @width;
    height: @height;
}

//== .background-opacity(#333, 0.5);
.background-opacity(@color, @opacity: 0.4) {
    background-color: @color; /* The Fallback */
    background-color: rgba(@color, @opacity);
}

//== .background-img-common(cover, repeat, top left);
.background-img-common(@bg-size:cover, @bg-repeat:no-repeat, @bg-pos:center center) {
    background-size: @bg-size;
    background-repeat: @bg-repeat;
    background-position: @bg-pos;
}

//== .onsolve-icon("c");
.onsolve-icon(@content) {
    font-family: "locamundo", sans-serif !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    content: @content;
}

//== .all-pos-zero;
.all-pos-zero {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

//== .column-count(2);
.column-count(@number) {
    -webkit-column-count: @number;
    -moz-column-count: @number;
    column-count: @number;
}

//== .column-gap(40px);
.column-gap(@gap) {
    -webkit-column-gap: @gap;
    -moz-column-gap: @gap;
    column-gap: @gap;
}


//== .placeholder-color (#ffffff);
.placeholder-color(@color) {
    &::-webkit-input-placeholder { /* WebKit browsers */
        color: @color;
        opacity: 1 !important;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: @color;
        opacity: 1 !important;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: @color;
        opacity: 1 !important;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: @color;
        opacity: 1 !important;
    }
}

//== .center-block();
.center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

//== .rotate(45deg);
.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
    -ms-transform: rotate(@degrees);
    -o-transform: rotate(@degrees);
    transform: rotate(@degrees);
}

//== .translate3d(0, 30px, 0);
.translate3d(@x, @y, @z) {
    -webkit-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}

//== .box-shadow(inset 0 3px 5px #000);
.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
    box-shadow: @shadow;
}

//== .box-none-shadow();
.box-none-shadow() {
    -webkit-box-shadow: none; // iOS <4.3 & Android <4.1
    box-shadow: none;
}

//== .text-overflow();
.text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Flexbox display
.flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
.flex(@values) {
    -webkit-box-flex: @values;
    -moz-box-flex: @values;
    -webkit-flex: @values;
    -ms-flex: @values;
    flex: @values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
.flex-direction(@direction) {
    -webkit-flex-direction: @direction;
    -moz-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap) {
    -webkit-flex-wrap: @wrap;
    -moz-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
.flex-flow(@flow) {
    -webkit-flex-flow: @flow;
    -moz-flex-flow: @flow;
    -ms-flex-flow: @flow;
    flex-flow: @flow;
}

// Display Order
// - applies to: flex items
// <integer>
.order(@val) {
    -webkit-box-ordinal-group: @val;
    -moz-box-ordinal-group: @val;
    -ms-flex-order: @val;
    -webkit-order: @val;
    order: @val;
}

// Flex grow factor
// - applies to: flex items
// <number>
.flex-grow(@grow) {
    -webkit-flex-grow: @grow;
    -moz-flex-grow: @grow;
    -ms-flex-grow: @grow;
    flex-grow: @grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
.flex-shrink(@shrink) {
    -webkit-flex-shrink: @shrink;
    -moz-flex-shrink: @shrink;
    -ms-flex-shrink: @shrink;
    flex-shrink: @shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
.flex-basis(@width) {
    -webkit-flex-basis: @width;
    -moz-flex-basis: @width;
    -ms-flex-basis: @width;
    flex-basis: @width;

}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
.justify-content(@justify) {
    -webkit-justify-content: @justify;
    -moz-justify-content: @justify;
    -ms-justify-content: @justify;
    justify-content: @justify;
    -ms-flex-pack: @justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
.align-content(@align) {
    -webkit-align-content: @align;
    -moz-align-content: @align;
    -ms-align-content: @align;
    align-content: @align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
.align-items(@align) {
    -webkit-align-items: @align;
    -moz-align-items: @align;
    -ms-align-items: @align;
    align-items: @align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
.align-self(@align) {
    -webkit-align-self: @align;
    -moz-align-self: @align;
    -ms-align-self: @align;
    align-self: @align;
}
