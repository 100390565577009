/*@font-icon:               "onsolve", sans-serif;*/
/* POST TEXT */
.all-pos-zero {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
a,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  list-style: none;
  outline: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
  -webkit-text-size-adjust: none;
  height: 100%;
  width: 100%;
}
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}
iframe {
  vertical-align: top;
}
input[type="text"],
input[type="submit"],
input[type="button"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  outline: none;
}
textarea:focus,
input:focus,
a,
div,
img {
  outline: none;
}
.cf:before,
.clearfix:before,
.cf:after,
.clearfix:after {
  content: " ";
  display: table;
}
.cf:after,
.clearfix:after {
  clear: both;
}
html,
body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
}
body {
  font-family: 'Flama';
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 300;
  color: #2F2F37;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
@media (max-width: 1023px) {
  body {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 1.2rem;
  }
}
a {
  color: #2F2F37;
}
a:hover,
a:focus {
  color: #117AA4;
}
/* POST TEXT */
p,
ul li,
ol li {
  font-size: 2.2rem;
  line-height: 1.5;
  /*letter-spacing: -.005rem;*/
  letter-spacing: -0.0025em;
  color: rgba(0, 0, 0, 0.84);
  font-weight: 400;
}
@media (max-width: 767px) {
  p,
  ul li,
  ol li {
    margin: 0 0 10px;
    font-size: 16px;
  }
}
p {
  /*margin: 2.35rem 0 0;*/
  margin: 0 0 1em 0;
  /*  &:last-child{
    margin-bottom: 15px;
}*/
}
@media (max-width: 767px) {
  p {
    margin: 0 0 10px;
    font-size: 16px;
  }
}
img {
  max-width: 100%;
}
button:focus,
button:focus,
button:active,
button.current,
div:focus,
a:focus,
input:focus,
image:focus {
  outline: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Flama Condensed';
  font-weight: 700;
  line-height: 1.2;
  color: #2F2F37;
  margin: 0 0 .5em;
}
@media (max-width: 767px) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 0 0 10px;
  }
}
h1,
.h1 {
  font-size: 4rem;
  font-weight: 800;
}
h2,
.h2 {
  font-size: 3.8rem;
}
h3,
.h3 {
  font-size: 3.4rem;
}
h4,
.h4 {
  font-size: 2.8rem;
}
h5,
.h5 {
  font-size: 2.4rem;
}
h6,
.h6 {
  font-size: 2rem;
}
@media (max-width: 1023px) {
  h1,
  .h1 {
    font-size: 3.2rem;
  }
  h2,
  .h2 {
    font-size: 3.2rem;
  }
  h3,
  .h3 {
    font-size: 3rem;
  }
  h4,
  .h4 {
    font-size: 2.4rem;
  }
  h5,
  .h5 {
    font-size: 2rem;
  }
  h6,
  .h6 {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 30px;
  }
  h2,
  .h2 {
    font-size: 26px;
  }
  h3,
  .h3 {
    font-size: 22px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
  h5,
  .h5 {
    font-size: 18px;
  }
  h6,
  .h6 {
    font-size: 16px;
  }
}
ul,
ol {
  margin: .25rem 3rem 2rem;
  padding: 0;
}
ul li {
  padding-left: 0;
  position: relative;
}
ul > li {
  list-style: outside disc;
}
ol li {
  color: #2F2F37;
  padding-left: 0;
  position: relative;
}
ol > li {
  list-style: outside decimal;
}
ol ul,
ol ol {
  margin: 10px 20px;
}
blockquote {
  display: block;
  margin: 40px 0 30px;
  position: relative;
  padding: 30px 30px;
  border: 2px solid #2F2F37;
  text-align: center;
}
@media (max-width: 1023px) {
  blockquote {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  blockquote {
    padding: 20px 15px 20px;
  }
}
blockquote p {
  font-size: 1.6rem;
  line-height: 1.2;
  color: #2F2F37;
}
@media (max-width: 1023px) {
  blockquote p {
    font-size: -0.4rem;
  }
}
@media (max-width: 767px) {
  blockquote p {
    font-size: -2.4rem;
  }
}
hr {
  width: 100%;
  border: 0 solid #808080;
  border-top-width: 1px;
  clear: both;
  height: 0;
  margin: 15px auto;
}
img {
  border: none;
  border-style: none;
  vertical-align: top;
  height: auto;
  max-width: 100%;
}
.aligncenter {
  display: block;
  clear: both;
  margin: 30px auto;
}
@media (max-width: 767px) {
  .aligncenter {
    margin: 20px auto;
  }
}
.alignleft {
  float: left;
  margin: 30px 30px 30px 0;
}
@media (max-width: 767px) {
  .alignleft {
    margin: 20px auto;
    float: none;
    display: block;
  }
}
.alignright {
  float: right;
  margin: 30px 0 30px 30px;
}
@media (max-width: 767px) {
  .alignright {
    margin: 20px auto;
    float: none;
    display: block;
  }
}
.dis-table {
  display: table;
  height: 100%;
  width: 100%;
}
.dis-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.container {
  max-width: 1230px;
  padding: 0 15px;
  max-width: 100%;
  margin: 0 auto;
}
.container-full {
  max-width: none;
}
#wrapper {
  width: 100%;
  min-height: 100%;
  position: relative;
  background-color: #fff;
  z-index: 8;
}
@media (max-width: 767px) {
  #wrapper {
    box-shadow: 0 -14px 10px #000000;
  }
}
.btn,
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  font-size: 1.6rem;
  font-family: 'Flama SemiCondensed';
  font-weight: 800;
  color: #117AA4;
  height: auto;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 1em ;
  position: relative;
  border: 2px solid #117AA4;
  background-color: transparent;
  border-radius: 0;
  text-transform: uppercase ;
}
.btn:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
button:hover {
  text-decoration: none;
  background-color: #117AA4;
  color: #fff;
}
@media (max-width: 767px) {
  .btn,
  input[type="submit"],
  input[type="button"],
  input[type="reset"],
  button {
    font-size: 15px;
    padding: 1em;
  }
}
.btn.btn-flat,
input[type="submit"].btn-flat,
input[type="button"].btn-flat,
input[type="reset"].btn-flat,
button.btn-flat {
  font-size: 1.4rem;
  padding: 10px 15px;
}
.btn.btn-default,
input[type="submit"].btn-default,
.btninput[type="submit"],
input[type="button"].btn-default,
.btninput[type="button"],
input[type="reset"].btn-default,
.btninput[type="reset"],
button.btn-default,
.btnbutton {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 10px 15px;
  border-width: 1px;
  margin: 5px;
}
.btn.btn-reverse,
input[type="submit"].btn-reverse,
input[type="button"].btn-reverse,
input[type="reset"].btn-reverse,
button.btn-reverse {
  background: #117AA4;
  color: white;
}
.btn.btn-reverse:hover,
input[type="submit"].btn-reverse:hover,
input[type="button"].btn-reverse:hover,
input[type="reset"].btn-reverse:hover,
button.btn-reverse:hover {
  background: rgba(17, 122, 164, 0.8);
}
.btn.login,
input[type="submit"].login,
input[type="button"].login,
input[type="reset"].login,
button.login {
  width: 100%;
}
.btn.fb-login-btn,
input[type="submit"].fb-login-btn,
input[type="button"].fb-login-btn,
input[type="reset"].fb-login-btn,
button.fb-login-btn {
  border-color: #3B5992;
  background-color: #fff;
  color: #3B5992;
  width: 100%;
}
label {
  display: block;
  /*color: @label-color;
    font-weight: @label-font-weight;
    font-family: @label-font-family;
    font-size: @label-font-size;*/
  line-height: 1.2;
  width: 100%;
  margin: 0 0 7px;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="date"],
textarea {
  -webkit-appearance: none;
  outline: none;
  padding: 0 15px;
  font-family: 'Flama';
  width: 100%;
  border: none;
  background-image: none;
  display: block;
  border: 1px solid #808080;
  margin: 0 0 10px;
  padding: 10px 15px;
  font-size: 15px;
  color: #2F2F37;
  margin-bottom: 0;
  margin-bottom: 10px;
  border-radius: 0;
}
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #1F1F1F;
  opacity: 1 !important;
}
input[type="text"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="date"]:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #1F1F1F;
  opacity: 1 !important;
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #1F1F1F;
  opacity: 1 !important;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #1F1F1F;
  opacity: 1 !important;
}
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
input[type="search"]:disabled,
input[type="number"]:disabled,
input[type="date"]:disabled,
textarea:disabled {
  background-color: #eeeeee;
}
textarea {
  padding: 30px 15px;
  resize: none;
  height: 150px;
}
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: auto;
  display: block;
}
button:focus,
button:focus,
button:active,
button.current,
div:focus,
a:focus,
input:foc us,
image:focus {
  outline: none;
}
select:-moz-focusring,
select::-moz-focus-inner {
  color: transparent !important;
  background-image: none !important;
  text-shadow: 0 0 0 #1a1a1a !important;
  border: 0;
  border-color: inherit;
  -webkit-box-shadow: none;
}
select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #2F2F37;
  background-color: #fff;
  background-image: none;
  border: 1px solid #808080;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin: 0 0 10px;
}
select:focus {
  outline: none;
}
.checkbox-custom {
  position: relative;
  cursor: pointer;
}
.checkbox-custom input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.checkbox-custom input[type="checkbox"]:checked + .checkbox-material .check::before {
  display: block;
}
.checkbox-custom .checkbox-material {
  vertical-align: top;
  position: relative;
  margin-right: 10px;
  line-height: 1;
}
.checkbox-custom .checkbox-material .check {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-color: #fff;
}
.checkbox-custom .checkbox-material .check:before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  margin: auto;
  position: absolute;
  background-image: url('images/check-mark.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px auto;
}
.radiobox-custom {
  position: relative;
  cursor: pointer;
}
.radiobox-custom input[type="radio"] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.radiobox-custom input[type="radio"]:checked + .radiobox-material .check::before {
  display: block;
}
.radiobox-custom .radiobox-material {
  vertical-align: top;
  position: relative;
  margin-right: 10px;
  line-height: 1;
}
.radiobox-custom .radiobox-material .check {
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-color: #fff;
}
.radiobox-custom .radiobox-material .check:before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 8px;
  height: 8px;
  display: none;
  margin: auto;
  position: absolute;
  border-radius: 100%;
  background-image: none;
}
.scroll-hidden {
  overflow: hidden;
  height: 100%;
  position: fixed;
}
.pagination-wrapper {
  border-top: 1px solid #ccc;
}
.pagination-wrapper .pagination {
  display: inline-flex;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination-wrapper .pagination > li {
  margin: 0;
  letter-spacing: normal;
  display: inline-flex;
  vertical-align: top;
}
@media (max-width: 767px) {
  .pagination-wrapper .pagination > li {
    font-size: 15px;
  }
}
.pagination-wrapper .pagination > li > a {
  border-color: #2F2F37;
  color: #2F2F37;
  position: relative;
  padding: 6px 12px;
  border: 1px solid #2F2F37;
  font-weight: 400;
  margin-left: -1px;
  line-height: normal;
}
.pagination-wrapper .pagination > li.active a {
  color: #fff;
  background-color: #2F2F37;
}
.pagination-wrapper .pagination > li:hover:not(.active) a {
  background-color: #787878;
  color: #fff;
}
.text-center {
  text-align: center;
}
/* */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
@media (max-width: 767px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
[class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
/* flex box */
.d-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center ;
}
.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}
.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
/* column structure */
/* ----- Extra small devices ≤640px ----- */
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
.col-offset-12 {
  margin-left: 100%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
.col-offset-0 {
  margin-left: 0;
}
/* ----- Small devices ≥641px ----- */
@media (min-width: 641px) {
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
/* ----- Medium devices ≥768px ----- */
@media (min-width: 768px) {
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
/* ----- Large devices ≥1024px ----- */
@media (min-width: 1024px) {
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}
/* ----- Extra Large devices ≥1200px ----- */
@media (min-width: 1280px) {
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
}
/* ----- Extra Large devices ≥1600px ----- */
@media (min-width: 1600px) {
  .col-xxl-12 {
    width: 100%;
  }
  .col-xxl-11 {
    width: 91.66666667%;
  }
  .col-xxl-10 {
    width: 83.33333333%;
  }
  .col-xxl-9 {
    width: 75%;
  }
  .col-xxl-8 {
    width: 66.66666667%;
  }
  .col-xxl-7 {
    width: 58.33333333%;
  }
  .col-xxl-6 {
    width: 50%;
  }
  .col-xxl-5 {
    width: 41.66666667%;
  }
  .col-xxl-4 {
    width: 33.33333333%;
  }
  .col-xxl-3 {
    width: 25%;
  }
  .col-xxl-2 {
    width: 16.66666667%;
  }
  .col-xxl-1 {
    width: 8.33333333%;
  }
  .col-xxl-offset-12 {
    margin-left: 100%;
  }
  .col-xxl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xxl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xxl-offset-9 {
    margin-left: 75%;
  }
  .col-xxl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xxl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xxl-offset-6 {
    margin-left: 50%;
  }
  .col-xxl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xxl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xxl-offset-3 {
    margin-left: 25%;
  }
  .col-xxl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xxl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xxl-offset-0 {
    margin-left: 0;
  }
}
/* hidden element */
.hidden {
  display: none;
}
/* ----- Extra small devices ≤640px ----- */
@media (max-width: 640px) {
  .hidden-xs-down {
    display: none;
  }
}
/* ----- Small devices ≥641px ----- */
@media (min-width: 641px) {
  .hidden-sm-up {
    display: none;
  }
}
/* ----- Small devices ≤767px ----- */
@media (max-width: 767px) {
  .hidden-sm-down {
    display: none;
  }
}
/* ----- Medium devices ≥768px ----- */
@media (min-width: 768px) {
  .hidden-md-up {
    display: none;
  }
}
/* ----- Medium devices ≤1024px ----- */
@media (max-width: 1023px) {
  .hidden-md-down {
    display: none;
  }
}
/* ----- Large devices ≥1024px ----- */
@media (min-width: 1024px) {
  .hidden-xl-up {
    display: none;
  }
}
/* Wordpress align class */
.alignnone {
  float: none;
  margin: 20px 0;
}
.aligncenter {
  display: block;
  clear: both;
  margin: 20px auto;
}
.alignleft {
  float: left;
  margin: 0 20px 20px 0;
}
.alignright {
  float: right;
  margin: 0 0 20px 20px;
}
/* text alignment */
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
/* images */
.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
}
.img-full {
  width: 100%;
  display: block;
}
/* float class */
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-none {
  float: none;
}
/* mange spacing */
.space-small {
  padding-top: 30px;
  padding-bottom: 30px;
}
.space-medium {
  padding-top: 50px;
  padding-bottom: 50px;
}
.space-large {
  padding-top: 70px;
  padding-bottom: 70px;
}
.no-bdr {
  border-width: 0;
}
/* padding */
.no-padding {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.pt-1 {
  padding-top: 20px;
}
.pb-1 {
  padding-bottom: 20px;
}
.pl-1 {
  padding-left: 20px;
}
.pr-1 {
  padding-right: 20px;
}
/* margin */
.no-margin {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.mr-0 {
  margin-right: 0;
}
.mt-1 {
  margin-top: 20px;
}
.mb-1 {
  margin-bottom: 20px;
}
.ml-1 {
  margin-left: 20px;
}
.mr-1 {
  margin-right: 20px;
}
header {
  min-width: 200px;
  width: 20%;
  position: relative;
  height: 100vh;
  display: inline-block;
  z-index: 9999;
}
@media (max-width: 1100px) {
  header {
    width: 8vw;
    min-width: 10px;
  }
}
@media (max-width: 767px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 3vh 5vw;
    height: auto;
    height: 70px;
  }
}
@media (max-width: 767px) {
  header.is-scroll {
    padding: 2vh 5vw;
    box-shadow: 0px 3px 18px 2px rgba(0, 0, 0, 0.35);
  }
}
header .inner-header {
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  height: 100%;
  padding: 3vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 110px;
  background-color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  /* Tablet */
}
@media (max-width: 1100px) {
  header .inner-header {
    /*width: 31vw;
            left: -21vw;*/
    padding-right: 70px;
    width: 250px;
    left: -180px;
  }
}
@media (max-width: 767px) {
  header .inner-header {
    padding: 0;
    width: 100%;
    left: 0;
    position: static;
    justify-content: center;
    align-items: flex-start;
  }
}
header .inner-header.search-open {
  width: auto;
  z-index: 1;
  pointer-events: none;
}
header .inner-header.search-open .navigation,
header .inner-header.search-open .search-btn,
header .inner-header.search-open .login-section {
  display: none;
}
header .inner-header.search-open .brand {
  pointer-events: auto;
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 1100px) {
  header.is-open .inner-header {
    left: 0;
    box-shadow: 0px 3px 18px 2px rgba(0, 0, 0, 0.35);
  }
}
header .brand {
  position: fixed;
  left: 1.6vw;
  top: 1.7vw;
  min-width: 30px;
  margin-bottom: 0;
  display: inline-block;
  width: 4vw;
  min-width: 50px;
  /*margin-bottom: 8vh;*/
}
@media (max-width: 1023px) {
  header .brand {
    left: 1.4vw;
  }
}
header .brand img {
  max-width: 100%;
}
@media (max-width: 767px) {
  header .brand {
    position: relative;
    width: 7vh;
    top: auto;
    left: auto;
    z-index: 1;
  }
}
header .navigation {
  margin-top: 20vh;
}
@media (max-width: 1100px) {
  header .navigation {
    margin-top: 18vh;
  }
}
@media (max-width: 767px) {
  header .navigation {
    margin-top: 0;
  }
}
header .navigation .hamburg-menu {
  display: none;
}
@media (max-width: 1100px) {
  header .navigation .hamburg-menu {
    width: 2.4vw;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 15px;
    transform: translateY(-100%);
    transition-duration: 0.5s;
    min-width: 36px;
  }
  header .navigation .hamburg-menu span {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 100%;
    top: 50%;
    margin-top: -2px;
    background-color: #212121;
  }
  header .navigation .hamburg-menu span:before {
    transition-duration: 0.5s;
    position: absolute;
    width: inherit;
    height: 4px;
    background-color: #212121;
    content: "";
    top: -11px;
  }
  header .navigation .hamburg-menu span:after {
    transition-duration: 0.5s;
    position: absolute;
    width: inherit;
    height: 4px;
    background-color: #212121;
    content: "";
    top: 11px;
  }
}
@media (max-width: 767px) {
  header .navigation .hamburg-menu {
    transform: translateY(-50%);
    top: 50%;
    right: 20px;
  }
}
@media (max-width: 640px) {
  header .navigation .hamburg-menu {
    width: 35px;
  }
}
header .navigation .hamburg-menu.is-open span {
  background-color: rgba(0, 0, 0, 0);
}
header .navigation .hamburg-menu.is-open span:before {
  top: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background: #212121;
}
header .navigation .hamburg-menu.is-open span:after {
  top: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background: #212121;
}
header .navigation ul {
  margin: 0;
  position: relative;
}
header .navigation ul li {
  list-style: none;
  font-size: 2.6rem;
  font-weight: 300;
  font-family: 'Flama';
  color: #2F2F37;
  margin-bottom: .5vh;
  line-height: normal;
}
header .navigation ul li a {
  color: inherit;
  display: inline-block;
  line-height: 1.25em;
}
header .navigation ul li a:hover {
  text-decoration: none;
  color: #117AA4;
}
@media (max-width: 767px) {
  header .navigation ul {
    display: none;
  }
}
header .navigation ul + ul:before {
  content: "";
  width: 33px;
  height: 3px;
  background-color: #808080;
  display: block;
  margin-top: 18px;
  margin-bottom: 15px;
}
header .login-section {
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 3vh;
}
header .login-section p {
  line-height: 1.2;
  margin-bottom: 10px;
}
header .login-section .login-signup {
  font-family: 'Flama SemiCondensed';
}
header .login-section .login-signup a {
  color: #2F2F37;
  font-weight: bold;
}
header .login-section .login-signup a:hover {
  text-decoration: none;
  color: #117AA4;
}
@media (max-width: 1100px) {
  header .login-section {
    padding: 20px 70px 20px 20px;
  }
}
@media (max-width: 767px) {
  header .login-section {
    display: none;
  }
}
header .search-btn {
  position: relative;
  font-family: 'Flama SemiCondensed';
  font-size: 1.8rem;
  font-weight: 600;
  color: #2F2F37;
}
header .search-btn:before {
  content: "";
  width: 33px;
  height: 3px;
  background-color: #000;
  display: block;
  margin-top: 18px;
  margin-bottom: 15px;
}
header .search-btn a {
  pointer-events: visible !important;
  color: #2F2F37;
}
header .search-btn i {
  margin-left: 5px;
}
@media (max-width: 767px) {
  header .search-btn {
    font-size: 0;
    position: absolute;
    right: 75px;
    top: 25px;
  }
  header .search-btn:before {
    display: none;
  }
  header .search-btn i {
    margin-left: 0;
    font-size: 25px;
  }
}
@media (min-width: 1101px) {
  .main-content.full-container header {
    width: 7vw;
    min-width: 10px;
  }
  .main-content.full-container header .inner-header {
    /*width: 31vw;
                left: -21vw;*/
    padding-right: 70px;
    width: 300px;
    left: -230px;
  }
  .main-content.full-container header .brand {
    /*position: fixed;                
                left: 27px;
                top: 1.5vw;     
                min-width: 30px;
                margin-bottom: 0; */
  }
  .main-content.full-container header .navigation .hamburg-menu {
    width: 2.4vw;
    height: 40px;
    display: inline-block;
    position: fixed;
    /*right: -10px;*/
    left: 2.3vw;
    transform: translateY(-100%);
    transition-duration: 0.4s;
    cursor: pointer;
    /*span{
                    background-color: rgba(0,0,0,.0);
                    &:before{
                    top: 0;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    background: rgba(72, 72, 69, 0.85);
                }
                    &:after{
                    top: 0;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    background: rgba(72, 72, 69, 0.85);
                }
                } */
  }
  .main-content.full-container header .navigation .hamburg-menu.is-open {
    left: 240px;
  }
  .main-content.full-container header .navigation .hamburg-menu.is-open span {
    background-color: rgba(0, 0, 0, 0);
  }
  .main-content.full-container header .navigation .hamburg-menu.is-open span:before {
    top: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: #212121;
  }
  .main-content.full-container header .navigation .hamburg-menu.is-open span:after {
    top: 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    background: #212121;
  }
  .main-content.full-container header .navigation .hamburg-menu span {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 100%;
    top: 50%;
    margin-top: -2px;
    background-color: #212121;
  }
  .main-content.full-container header .navigation .hamburg-menu span:before {
    transition-duration: 0.5s;
    position: absolute;
    width: inherit;
    height: 4px;
    background-color: #212121;
    content: "";
    top: -0.7vw;
  }
  .main-content.full-container header .navigation .hamburg-menu span:after {
    content: "";
    width: inherit;
    height: 4px;
    position: absolute;
    top: 0.7vw;
    background-color: #212121;
    transition-duration: 0.5s;
  }
  .main-content.full-container header .login-section {
    padding: 20px 70px 20px 20px;
  }
  .main-content.full-container .site-content {
    width: 100%;
    max-width: calc(100% - 14vw);
  }
}
@media (min-width: 1101px) and (min-width: 768px) {
  .main-content.full-container header.is-open .inner-header {
    left: 0;
    box-shadow: 0px 3px 18px 2px rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 1101px) and (max-width: 640px) {
  .main-content.full-container header .navigation .hamburg-menu.is-open span:before {
    -webkit-transform: rotateZ(-45deg) scaleX(0.75) translate(-11px, -3px);
    transform: rotateZ(-45deg) scaleX(0.75) translate(-11px, -3px);
  }
  .main-content.full-container header .navigation .hamburg-menu.is-open span:after {
    -webkit-transform: rotateZ(45deg) scaleX(0.75) translate(-11px, 3px);
    transform: rotateZ(45deg) scaleX(0.75) translate(-11px, 3px);
  }
}
.inner-wrap .navigation .account-nav,
.inner-wrap .navigation nav,
.inner-wrap .navigation .nav-more {
  display: none;
}
header.is-open #mobile-menu {
  left: 0;
  width: 100%;
}
#mobile-menu {
  display: none;
  padding: 5vh 5vw;
  overflow: auto;
}
@media (max-width: 767px) {
  #mobile-menu {
    display: block;
    background-color: #fff;
    position: fixed;
    top: 70px;
    left: -100%;
    width: calc(100% - 67px);
    height: 100%;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    height: calc(100% - 70px);
  }
}
#mobile-menu ul {
  margin: 0;
  position: relative;
}
#mobile-menu ul li {
  list-style: none;
  font-size: 26px;
  font-weight: 300;
  font-family: 'Flama';
  color: #2F2F37;
  margin-bottom: 7px;
}
@media (max-width: 640px) {
}
#mobile-menu ul li a {
  display: block;
  line-height: normal;
  color: #2F2F37 !important;
}
#mobile-menu .m-login-section {
  margin-top: 30px;
}
#mobile-menu .m-login-section p {
  margin-bottom: 0;
}
#mobile-menu .m-login-section .login-signup {
  font-family: 'Flama SemiCondensed';
}
#mobile-menu .m-login-section .login-signup a {
  color: #2F2F37;
  font-weight: bold;
  font-size: 14px;
}
#mobile-menu .m-login-section .login-signup a:hover {
  text-decoration: none;
  color: #117AA4;
}
/* serch bar popup */
.search-popup {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  padding: 10vh 10vw;
  transform: scale(0);
}
.search-popup input {
  border: 0;
  height: 10vh;
  font-size: 5vh;
}
.search-popup input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
  opacity: 1 !important;
}
.search-popup input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
  opacity: 1 !important;
}
.search-popup input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
  opacity: 1 !important;
}
.search-popup input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
  opacity: 1 !important;
}
.search-popup .form-col:first-of-type {
  border-bottom: 1px solid #ccc;
}
.search-popup .submit-form-btn {
  display: none;
}
.search-popup .close-btn {
  position: absolute;
  top: 3vh;
  right: 3vh;
  font-size: 26px;
  pointer-events: visible !important;
  color: #2F2F37;
}
.search-popup.is-open {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
@media (max-width: 767px) {
  .search-popup .form {
    margin-top: 70px;
  }
}
/*.main-content.full-container header .navigation .hamburg-menu span*/
.main-content:not(.full-container) .footer {
  display: none;
}
@media (max-width: 767px) {
  .main-content:not(.full-container) .footer {
    display: flex;
    padding: 4vw 5vw;
  }
}
@media (max-width: 767px) {
  .main-content:not(.full-container) .footer {
    justify-content: center;
    text-align: center;
  }
}
.main-content .inner-wrap .footer {
  display: flex;
}
@media (max-width: 767px) {
  .main-content .inner-wrap .footer {
    display: none;
  }
}
.footer {
  padding: 20px 0;
}
.footer-nav {
  display: inline-flex;
  margin-left: 0;
  margin-bottom: 0;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .footer-nav {
    margin-right: 0;
  }
}
.footer-nav li {
  list-style: none;
  margin-right: 0;
}
.footer-nav li a {
  position: relative;
  color: #2F2F37;
}
.footer-nav li a:after {
  content: "|";
  display: inline-block;
  margin: 0 5px;
}
.footer-nav li a:hover {
  color: #117AA4;
}
.footer-nav li:last-child a:after {
  display: none;
}
.footer-social {
  display: flex;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.footer-social li {
  list-style: none;
}
.footer-social li a {
  font-size: 50px;
  width: 1em;
  height: 1em;
  display: block;
  position: relative;
}
.footer-social li a img {
  vertical-align: top;
}
.footer-social li a:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  body {
    max-width: 100%;
    padding-top: 70px;
    overflow-x: hidden;
    /* for ad */
  }
}
.main-content {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.site-content {
  max-width: calc(100% - 200px);
  width: 75%;
  display: inline-block;
}
@media (max-width: 1100px) {
  .site-content {
    max-width: calc(100% - 8vw);
    width: 100%;
  }
}
@media (max-width: 767px) {
  .site-content {
    max-width: 100%;
    /*margin-top: 12vh;*/
    /*margin-top: 70px;*/
  }
}
.inner-wrap {
  max-width: calc(100% - 254px);
  width: 75%;
  display: inline-block;
  vertical-align: top;
  padding-right: 3vw;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  .inner-wrap {
    padding-right: 20px;
  }
}
@media (max-width: 1023px) {
  .inner-wrap {
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .inner-wrap {
    max-width: 100%;
    width: 100%;
    padding-right: 0;
  }
}
.top-adbar {
  padding: 2vh 5vw;
  text-align: center;
  position: relative;
  z-index: 999;
  width: 100%;
  /*height: 165px;*/
}
@media (max-width: 1100px) {
  .top-adbar {
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 767px) {
  .top-adbar {
    max-height: 200px !important;
    padding: 1vh 5vw 0;
  }
}
.inner-wrap-collout {
  border-top: 1px solid #eee;
  width: 100%;
  padding: 30px 0 0;
  padding-left: 40px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1100px) {
  .inner-wrap-collout {
    padding: 20px 20px 0 20px;
  }
}
@media (max-width: 767px) {
  .inner-wrap-collout {
    padding: 4vh 5vw;
    border: none;
  }
}
.inner-wrap-collout.full-collout .inner-wrap {
  width: 100%;
  max-width: 100%;
  padding-right: 0;
}
/* banner section */
.banner-sec {
  padding: 2vw;
  background-color: #e5e5dc;
  margin: 3em 0;
}
@media (max-width: 767px) {
  .banner-sec {
    text-align: center;
  }
}
.banner-sec .text-part {
  width: 60%;
}
.banner-sec .text-part .btn,
.banner-sec .text-part input[type="submit"],
.banner-sec .text-part input[type="button"],
.banner-sec .text-part input[type="reset"],
.banner-sec .text-part button {
  margin-right: 5px;
  margin-bottom: 5px;
}
@media (max-width: 1023px) {
  .banner-sec .text-part {
    width: 100%;
  }
}
.banner-sec .img-part {
  width: 40%;
  text-align: right;
}
.banner-sec .img-part img {
  display: block;
  margin-left: auto;
  margin-right: 0;
}
@media (max-width: 1023px) {
  .banner-sec .img-part img {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .banner-sec .img-part img {
    margin: 0 auto;
  }
}
@media (max-width: 1023px) {
  .banner-sec .img-part {
    width: 100%;
    order: -1;
    margin-bottom: 10px;
  }
}
.featured {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
}
.featured .featured-post {
  max-width: calc(100% - 260px);
  width: 65%;
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
}
@media (max-width: 1023px) {
  .featured .featured-post {
    width: 100%;
    max-width: none;
    padding-right: 0;
    margin-bottom: 20px;
  }
}
.featured .featured-post figure img {
  width: 100%;
}
.featured .featured-posts {
  width: 35%;
  min-width: 260px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1023px) {
  .featured .featured-posts {
    width: 100%;
  }
}
.featured .featured-posts .item + .item {
  border-top: 1px solid #808080;
  padding-top: 10px;
}
.featured .featured-posts h5 {
  margin-bottom: 5px;
  font-weight: 800;
}
.featured .featured-posts p {
  font-family: 'Flama SemiCondensed';
  font-size: 15px;
}
.heading {
  margin-bottom: 20px;
}
.heading h1 {
  margin-bottom: 0;
}
.heading span {
  font-size: 16px;
  font-family: 'Flama Condensed';
  font-weight: normal;
}
.heading span strong {
  margin-left: 10px;
}
.latest-posts {
  margin-top: ;
}
@media (max-width: 1023px) {
}
.latest-posts .item {
  display: flex;
  border-top: 1px solid #808080;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.latest-posts .item:hover {
  opacity: 0.65;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .latest-posts .item {
    flex-wrap: wrap;
  }
}
.latest-posts .collout {
  width: 34%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .latest-posts .collout {
    width: 100%;
    padding-left: 0;
  }
  .latest-posts .collout + .collout {
    margin-top: 20px;
  }
}
.latest-posts .collout.desc {
  padding-left: 20px;
}
@media (max-width: 1024px) {
  .latest-posts .collout.desc {
    padding-left: 0;
  }
}
.latest-posts .collout.image {
  padding-left: 20px;
  text-align: right;
}
@media (max-width: 1024px) {
  .latest-posts .collout.image {
    text-align: center;
    padding-left: 0;
    width: 100%;
  }
}
.latest-posts .collout.image img {
  width: 100%;
}
.latest-posts .collout p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 1.2em;
  line-height: 1.4;
}
.side-ad-bar {
  position: relative;
  padding: 0 20px;
  min-width: 250px;
  width: 25%;
  display: inline-block;
  vertical-align: top;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .side-ad-bar {
    justify-content: flex-start;
    width: 100%;
    max-width: none;
    padding: 0;
    flex-direction: row;
    border-top: 1px solid #808080;
    padding-top: 20px;
  }
}
@media (max-width: 1023px) {
  .side-ad-bar {
    flex-wrap: wrap;
  }
}
@media (max-width: 640px) {
  .side-ad-bar {
    justify-content: center;
  }
}
.side-ad-bar .heading-text {
  margin-bottom: 20px;
}
.side-ad-bar .heading-text h1 {
  color: #117AA4;
  margin-bottom: 0px;
  line-height: 1;
}
.side-ad-bar .heading-text h6 {
  margin-bottom: 0;
}
.side-ad-bar .heading-text h6 span {
  color: #117AA4;
}
.side-ad-bar .heading-text small {
  font-size: 15px;
  font-family: 'Flama';
}
.side-ad-bar .right-content {
  /* position: fixed;*/
  /*width: 247px;*/
}
@media (max-width: 767px) {
  .side-ad-bar .right-content {
    position: relative;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .side-ad-bar .right-content {
    width: 50%;
  }
}
@media (max-width: 640px) {
  .side-ad-bar .right-content {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}
.side-ad-bar .right-sidebar-ad {
  position: fixed;
  display: block;
  margin-top: 50px;
  top: 60vh;
  /*height: 250px;*/
}
@media (max-width: 767px) {
  .side-ad-bar .right-sidebar-ad {
    position: static;
    width: 50%;
    margin-top: 0;
    text-align: center;
    padding: 4vh 5vw;
  }
}
@media (max-width: 640px) {
  .side-ad-bar .right-sidebar-ad {
    width: 100%;
  }
}
.partners .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  margin: 0;
}
@media (max-width: 768px) {
}
.partners .partner-block {
  max-width: 30%;
  margin-left: 1%;
  margin-right: 1%;
  padding: 10px;
  text-align: center;
}
.partners .partner-block .desc p {
  font-size: 1.8rem;
}
@media (max-width: 1024px) {
  .partners .partner-block {
    max-width: 40%;
  }
}
@media (max-width: 768px) {
  .partners .partner-block {
    max-width: 45%;
  }
}
/* event */
.page_header {
  margin-bottom: 20px;
}
.page_header .contain {
  position: relative;
  /*background-image: none !important;*/
  background-size: cover;
  background-position: center;
}
@media (min-width: 1280px) {
  .page_header .contain {
    height: 40vh;
    min-height: 250px;
  }
  .page_header .contain img {
    display: none;
  }
}
.page_header .contain .banner-caption {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  text-align: center;
  color: #fff;
  background-color: rgba(17, 17, 17, 0.5);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page_header .contain .banner-caption * {
  color: #fff;
}
.page_header .contain .banner-caption h1 {
  margin-bottom: 0;
}
.page_header form {
  font-family: 'Flama';
  font-size: 2.2rem;
  margin-top: 10px;
}
.page_header form input {
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  margin: 0;
  margin-bottom: 2px;
  padding: 0;
  border-radius: 0;
  color: #fff;
  padding: 5px 5px;
  display: inline-block;
  width: auto;
  font-weight: 400;
  height: 35px;
  margin: 0 5px 10px;
}
.page_header form input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
  opacity: 1 !important;
}
.page_header form input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1 !important;
}
.page_header form input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1 !important;
}
.page_header form input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
  opacity: 1 !important;
}
.page_header form input[type="submit"] {
  margin: 0;
  border: 1px solid #fff;
  padding: 7px 15px;
}
.event-list-2-columns .grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.event-list-2-columns .col {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .event-list-2-columns .col {
    max-width: 100%;
    width: 100%;
  }
}
.event-list-2-columns .col .btn,
.event-list-2-columns .col input[type="submit"],
.event-list-2-columns .col input[type="button"],
.event-list-2-columns .col input[type="reset"],
.event-list-2-columns .col button {
  margin: 5px;
}
@media (max-width: 767px) {
}
/* POST LIST PAGE TEMPLATE */
.featured-post-sec {
  margin-bottom: 0;
}
@media (max-width: 767px) {
}
.featured-post-sec .post-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  min-height: 400px;
  max-height: 35vh;
}
@media (max-width: 641px) {
  .featured-post-sec .post-img {
    min-height: 250px;
  }
}
@media (min-width: 1600px) {
  .featured-post-sec .post-text {
    padding: 0 20px;
  }
}
.featured-post-sec .post-text p {
  font-size: 110%;
}
.featured-post-sec .post-text .btn.btn-default,
.featured-post-sec .post-text input[type="submit"].btn-default,
.featured-post-sec .post-text .btninput[type="submit"],
.featured-post-sec .post-text input[type="button"].btn-default,
.featured-post-sec .post-text .btninput[type="button"],
.featured-post-sec .post-text input[type="reset"].btn-default,
.featured-post-sec .post-text .btninput[type="reset"],
.featured-post-sec .post-text button.btn-default,
.featured-post-sec .post-text .btnbutton {
  margin: 0;
  margin-top: 2vh;
}
.category-filters > p {
  font-family: 'Flama';
  text-align: center;
  margin: 4% auto;
  font-weight: 300;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
.category-filters > p a {
  color: #2F2F37;
  margin: 0 5%;
}
.category-filters > p a:hover {
  border-bottom: 2px solid #2F2F37;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .category-filters > p a {
    margin: 0 4%;
  }
}
@media (max-width: 1023px) {
  .category-filters > p a {
    margin: 0 3%;
  }
}
@media (max-width: 640px) {
  .category-filters > p a {
    margin: 0 1.8%;
  }
}
@media (max-width: 1023px) {
  .category-filters > p {
    font-size: 1.8rem;
  }
}
@media (max-width: 640px) {
  .category-filters > p {
    font-size: 15px;
  }
}
.divider {
  text-align: center;
  font-family: 'Flama';
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.divider .line {
  position: absolute;
  background: #ccc;
  height: .1em;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
}
.divider h4 {
  position: relative;
  display: inline-block;
  padding: 2.5vw 3vw;
  margin-bottom: 0;
  background-color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.grid-list {
  padding: 0;
}
@media (max-width: 767px) {
}
@media (max-width: 640px) {
  .grid-list {
    justify-content: center;
  }
}
.grid-list .item {
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
}
.grid-list .item:hover .roll {
  background: rgba(31, 31, 31, 0.7);
  justify-content: center;
  align-items: center;
}
.grid-list .item:hover .roll i {
  font-size: 25px;
}
.grid-list .item:hover .roll p {
  opacity: 1;
  display: block;
}
@media (max-width: 1100px) {
  .grid-list .item {
    margin-top: 2vh;
  }
}
@media (max-width: 640px) {
  .grid-list .item {
    max-width: 90vw;
    padding: 0;
    margin-top: 4vh;
  }
}
.grid-list a {
  display: block;
}
.grid-list .image {
  min-height: 130px;
  margin-bottom: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
}
@media (min-width: 1280px) {
  .grid-list .image {
    min-height: 200px;
  }
}
@media (max-width: 1024px) {
  .grid-list .image {
    min-height: 300px;
  }
}
@media (max-width: 641px) {
  .grid-list .image {
    min-height: 200px;
  }
}
@media (max-width: 640px) {
}
.grid-list .image .roll {
  color: #fff;
  height: 100%;
  min-height: 130px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding: 10px;
}
.grid-list .image .roll i {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.grid-list .image .roll p {
  margin: 0;
  color: inherit;
  opacity: 0;
  transition: .3s opacity ease;
  display: none;
  text-align: center;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
@media (min-width: 1280px) {
  .grid-list .image .roll {
    min-height: 200px;
  }
}
@media (max-width: 1024px) {
  .grid-list .image .roll {
    min-height: 300px;
  }
}
@media (max-width: 641px) {
  .grid-list .image .roll {
    min-height: 200px;
  }
}
.grid-list .title {
  font-family: 'Flama';
  color: #1F1F1F;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: .5em;
  font-weight: 600;
  font-size: 15px;
}
.grid-list .text {
  margin-bottom: 1.5vh;
}
.grid-list .text p {
  margin: 0.3rem 0;
}
.grid-list .text .blurb {
  font-family: 'Flama';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.grid-list .text .blurb p {
  font-size: 18px;
  margin-bottom: 0;
}
.grid-list .details .time {
  font-size: 1.5rem;
  color: rgba(31, 31, 31, 0.8);
}
.grid-list .slick-arrow {
  display: none !important;
}
.grid-list.slick-slider {
  margin-left: -10px;
  margin-right: -10px;
}
.info {
  margin-bottom: 20px;
}
.info .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .info .content {
    flex-wrap: wrap;
  }
}
.info .first {
  display: inline-flex;
  vertical-align: top;
}
.info .first p {
  margin-top: 0;
  margin-bottom: 0;
}
.blog-detail .title {
  margin-bottom: 20px;
}
.blog-detail .title h1 {
  margin-bottom: 0;
}
.blog-detail .title p {
  margin-top: 0;
}
.blog-detail .header {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  overflow: hidden;
  height: 40vh;
  min-height: 300px;
}
.blog-detail .header img {
  display: none;
}
.blog-detail .header.video-section {
  height: auto;
  min-height: 10px;
  /*.video-iframe{
                padding-top: 56.25%;
                position: relative;
                iframe{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }*/
}
.social-info {
  margin-top: 20px;
}
/* POST DETAIL */
.post-detail {
  max-width: 900px;
}
.post-detail .featured-img {
  margin-bottom: 20px;
  min-height: 400px;
  max-height: 40vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1023px) {
  .post-detail .featured-img {
    height: 35vh;
    min-height: 250px;
  }
}
.post-detail .title {
  margin-bottom: 20px;
}
.post-detail .title h1 {
  margin-bottom: 0;
}
.post-detail .title .p-large {
  font-family: 'Flama Condensed';
  font-size: 15px;
}
.post-detail .title .p-large i {
  font-style: normal;
}
.post-detail .post {
  max-width: 800px;
}
.post-detail .post .post_share {
  margin-top: 4rem;
}
.post-detail .post .about-author {
  border-top: 1px solid #000;
  margin-top: 4rem;
  padding-top: 1em;
}
/* search result */
.search-results .search-blocks + .search-blocks {
  border-top: 1px dotted;
  margin-bottom: 10px;
  /*padding-top: 10px;*/
}
.search-results .search-blocks {
  padding: 10px 0;
}
.search-results .search-blocks p a {
  font-weight: 600;
  color: #117AA4;
  font-family: 'Flama Condensed';
}
.search-results .search-blocks p a:hover {
  text-decoration: underline;
}
.cart-count {
  font-size: 15px;
}
.simple_search_form form {
  display: flex;
}
.simple_search_form form .txt-box {
  flex: 1 0 0%;
  margin: 0;
  margin-right: 1.2vw;
}
.simple_search_form form .btn,
.simple_search_form form input[type="submit"],
.simple_search_form form input[type="button"],
.simple_search_form form input[type="reset"],
.simple_search_form form button {
  width: 30%;
  max-width: 300px;
  margin: 0;
}
.at-resp-share-element .at-share-btn {
  margin-bottom: 0;
}
.grid-list.slick-slider .item {
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
}
.heading-title {
  margin-bottom: 10px;
}
.detail-content {
  width: 800px;
}
@media (max-width: 1023px) {
  .detail-content {
    width: auto;
  }
}
.detail-content h2 {
  text-transform: uppercase;
}
.detail-content .image {
  width: 250px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 767px) {
  .detail-content .image {
    display: block;
    width: auto;
  }
}
.detail-content .desc {
  width: calc(100% - 264px);
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .detail-content .desc {
    display: block;
    width: 100%;
  }
}
.detail-content .desc a {
  color: #117AA4;
}
.fancybox-content {
  max-width: 90% !important;
}
.heading-title2 {
  align-items: center;
}
.heading-title2 a {
  color: #117AA4;
  font-weight: 600;
  font-size: 14px;
}
.heading-title2 a:hover {
  text-decoration: underline;
}
/* mini laptop resolution 1349 X 662 and 1246 X 681 and 1218 X 672 */
@media (min-width: 1100px) and (max-height: 750px) {
}
/* ACCOUNT PAGE  /  INSIDER*/
.live-event-banner {
  margin: 30px 0;
}
/* loginh form */
.login-form {
  padding-top: 30px;
}
@media (max-width: 767px) {
}
.login-form .header {
  width: 100%;
  margin-bottom: 20px;
}
.login-form .header h4 {
  margin-bottom: 0;
}
.login-form .header p {
  font-family: 'Flama Condensed';
}
.login-form p {
  margin-bottom: 10px;
}
.login-form p.or {
  text-align: center;
}
.login-form p a {
  font-weight: 600;
  color: #117AA4;
  font-family: 'Flama Condensed';
}
.login-form p a:hover {
  text-decoration: underline;
}
.login-form .fb-login-btn {
  margin-bottom: 20px;
}
.featured-post-sec .post-text form .inp-qty {
  max-width: 80px;
  height: 38px;
  padding: 10px;
}
.featured-post-sec .post-text form .inp-qty,
.featured-post-sec .post-text form .inp-qty + input.btn,
.featured-post-sec .post-text form .inp-qty + inputinput[type="submit"],
.featured-post-sec .post-text form .inp-qty + inputinput[type="button"],
.featured-post-sec .post-text form .inp-qty + inputinput[type="reset"],
.featured-post-sec .post-text form .inp-qty + inputbutton {
  display: inline-block;
  width: auto;
  height: 38px;
  vertical-align: top;
  margin: 0;
  margin-right: 5px;
}
.featured-post-sec .post-text form button {
  margin-bottom: 10px;
}
#update_cart_form [class*=col-] {
  margin-bottom: 20px;
}
#update_cart_form .collapse {
  display: none;
  margin-top: 20px;
}
#update_cart_form .collapse ul li {
  font-size: 1.8rem;
}
#update_cart_form .buttons-row button {
  margin: 5px;
}
#update_cart_form .form-group {
  margin-top: 20px;
}
#update_cart_form .form-group .text-danger input {
  display: inline-block;
  vertical-align: top;
}
#update_cart_form .form-group .text-danger span {
  display: inline-block;
  vertical-align: top;
}
.comment-frm .comment-entries {
  margin-bottom: 20px;
  border-bottom: 1px solid #000;
}
.comment-frm .comment-entries label {
  font-weight: 600;
}
.comment-frm .comment-entries .comment_body {
  border-top: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.comment-frm .comment-entries .comment_body p {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .comment-frm .comment-entries .comment_body p {
    font-size: 15px;
  }
}
.comment-frm .comment-entries .comment_body span {
  font-family: 'Flama Condensed';
  font-weight: 600;
  align-self: flex-end;
  font-size: 1.6rem;
}
.comment-frm .captcha {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 767px) {
  .comment-frm .captcha {
    flex-wrap: nowrap;
  }
}
.comment-frm .captcha img {
  margin-right: 10px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .comment-frm .captcha img {
    max-width: 140px;
  }
}
.comment-frm .captcha input {
  width: auto;
  display: inline-block;
  margin: 0 0 5px 0;
  padding: 5px 10px;
}
@media (max-width: 767px) {
  .comment-frm .captcha input {
    flex: 1 1 0%;
    min-width: 50px;
    max-width: 150px;
  }
}
@media (max-width: 767px) {
}
