/* loginh form */
.login-form {
    @media (max-width : @screen-xs-max){
      //  text-align: center;
    }
    padding-top: 30px;
    .header{
        width: 100%;
        margin-bottom: 20px;
        h4{
            margin-bottom: 0;
        }
        p{
            font-family: @font-flamaCondensed;
        }
    }
    p{
        margin-bottom: 10px;
        &.or{
            text-align: center;
        }
        a{
            font-weight: 600;
            color: @blue-color;
            font-family: @font-flamaCondensed; 
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .fb-login-btn{
        margin-bottom: 20px;    
    }
}
.featured-post-sec {
    .post-text{
        form{
            .inp-qty{
                max-width: 80px;
                height: 38px;
                padding: 10px;
            }
            .inp-qty,
            .inp-qty + input.btn{
                display: inline-block;
                width: auto;       
                height: 38px;
                vertical-align: top;
                margin:0;
                margin-right: 5px;
            }
            button{
                margin-bottom: 10px;
            }
        }
    }
}

#update_cart_form{
    [class*=col-]{
        margin-bottom: 20px;
    }
    .collapse{
        display: none;
        margin-top: 20px;
        ul{
            li{
                font-size: 1.8rem;
            }
        }
    }
    .buttons-row{
        button{
            margin:5px;
        }
    }
    .form-group{
        margin-top: 20px;
        .text-danger{
            input{
                display: inline-block;
                vertical-align: top;
            }
            span{
                display: inline-block;
                vertical-align: top;
            }
        }
    }
}
.comment-frm{    
    .comment-entries{
        margin-bottom: 20px;
        border-bottom: 1px solid #000;
        label{
            font-weight: 600;
        }
        .comment_body{
            border-top: 1px solid #ccc;
            padding: 5px;
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            p{
                font-size: 1.8rem;
                margin-bottom: 10px;
                @media (max-width : @screen-xs-max){
                    font-size: 15px;
                }
            }
            span{                
                font-family: @font-flamaCondensed;
                font-weight: 600;
                align-self: flex-end;
                font-size: 1.6rem;
            }
        }
    }
    .captcha{
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media (max-width : @screen-xs-max){
            flex-wrap: nowrap;
        }
        img{
            margin-right: 10px;
            margin-bottom: 5px;
            @media (max-width : @screen-xs-max){
                max-width: 140px;
            }
        }
        input{
            width: auto;
            display: inline-block;
            margin: 0 0 5px 0;
            padding: 5px 10px;
            @media (max-width : @screen-xs-max){
                flex: 1 1 0%;
                min-width: 50px;
                max-width: 150px;
            }
        }
    }
    #content_comment_form{       
        @media (max-width : @screen-xs-max){
           // input[type="submit"]{margin: 0 auto;}
        }
    }
}