body{
    @media (max-width : @screen-xs-max){
        max-width: 100%;
        padding-top: 70px;
        overflow-x: hidden;/* for ad */
    }
}
.main-content{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}
.site-content{
    max-width: calc(~"100% - 200px");
    width: 75%;
    display: inline-block;
    @media (max-width: 1100px){
        max-width: calc(~"100% - 8vw");
        width: 100%;
    }
    @media (max-width : @screen-xs-max){
        max-width: 100%;
        /*margin-top: 12vh;*/
        /*margin-top: 70px;*/
    }
}
.inner-wrap{
    max-width: calc(~"100% - 254px");
    width: 75%;
    display: inline-block;
    vertical-align: top;
    padding-right: 3vw;
    margin-bottom: 0;
    @media (max-width : 1100px){
        padding-right: 20px;
    }
    @media (max-width : @screen-sm-max){
        padding-right: 20px;
    }
    @media (max-width : @screen-xs-max){
        max-width: 100%;
        width: 100%;
        padding-right: 0;
    }
}
.top-adbar{
    padding: 2vh 5vw;
    text-align: center;
    position: relative;
    z-index: 999;
    width: 100%;
    /*height: 165px;*/
    @media (max-width: 1100px){
        text-align: center;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    @media (max-width : @screen-xs-max){
        max-height: 200px !important;
        padding: 1vh 5vw 0;
    }
}
.inner-wrap-collout{
    border-top: 1px solid #eee;
    width: 100%;
    padding: 30px 0 0;
    padding-left: 40px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width : 1100px){
        padding: 20px 20px 0 20px;
    }
    @media (max-width : @screen-xs-max){
        padding: 4vh 5vw;
        border:none;
    }
    &.full-collout{
        .inner-wrap{
            width: 100%;
            max-width: 100%;
            padding-right: 0;
        }
    }
}
/* banner section */
.banner-sec{
    padding: 2vw;
    background-color: #e5e5dc;
    margin: 3em 0;
    @media (max-width : @screen-xs-max){
        text-align: center;
    }
    .text-part{
        width: 60%;
        .btn{
            margin-right: 5px;
            margin-bottom: 5px;
        }
        @media (max-width : @screen-sm-max){
            width: 100%;
        }
    }
    .img-part{
        width: 40%;
        text-align: right;
        img{
            display: block;
            margin-left: auto;
            margin-right: 0;
            @media (max-width : @screen-sm-max){
                margin-left: 0;
            }
            @media (max-width : @screen-xs-max){
                margin: 0 auto;
            }
        }
        @media (max-width : @screen-sm-max){
            width: 100%;
            order: -1;
            margin-bottom: 10px;
        }
    }
}

.featured{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @media (max-width : @screen-sm-max){
        //text-align: center;
    }
    .featured-post{
        max-width: calc(~"100% - 260px");
        width: 65%;
        display: inline-block;
        vertical-align: top;
        padding-right: 20px;
        @media (max-width : @screen-sm-max){
            width: 100%;
            max-width: none;
            padding-right: 0;
            margin-bottom: 20px;
        }
        figure{
            img{
                width: 100%;
            }
        }
    }
    .featured-posts {
        width: 35%;
        min-width: 260px;
        display: inline-block;
        vertical-align: top;
        @media (max-width : @screen-sm-max){
            width: 100%;
        }
        .item{
            + .item{
                border-top: 1px solid @light-gray;
                padding-top: 10px;
            }
        }
        h5{
            margin-bottom: 5px;
            font-weight: 800;
        }
        p{
            font-family: @font-flamaSemiCondensed;
            font-size: 15px;
        }
    }
}
.heading{
    margin-bottom: 20px;
    h1{
        margin-bottom: 0;

    }
    span{
        font-size: 16px;
        font-family: @font-flamaCondensed;
        font-weight: normal;
        strong{
            margin-left: 10px;
        }
    }
}
.latest-posts{
    @media (max-width : @screen-sm-max){
        //  text-align: center;
    }
    margin-top: ;
    .item{
        display: flex;
        border-top: 1px solid @light-gray;
        padding-top: 20px;
        padding-bottom: 20px;
        .transition(0.3s);
        &:hover{
            opacity: 0.65;
            cursor: pointer;
        }
        @media (max-width : @screen-sm-max){
            flex-wrap: wrap;
        }
    }
    .collout{
        width: 34%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 0;

        @media (max-width : @screen-sm-max){
            width: 100%;
            padding-left: 0;
            +.collout{
                margin-top: 20px;
            }
        }

        &.desc {
            padding-left: 20px;

            @media (max-width: @screen-md) {
                padding-left: 0;
            }

        }
        &.image {
            padding-left: 20px;
            text-align: right;

            @media (max-width: @screen-md) {
                text-align: center;
                padding-left: 0;
                width: 100%;
            }
            img {
                width: 100%;
            }

        }
        p{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            font-size: 1.2em;
            line-height: 1.4;
        }
    }
}
.side-ad-bar{
    position: relative;
    padding: 0 20px;
    min-width: 250px;
    width: 25%;
    display: inline-block;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width : @screen-xs-max){
        justify-content: flex-start;
        width: 100%;
        max-width: none;
        padding: 0;
        flex-direction: row;
        border-top: 1px solid @light-gray;
        padding-top: 20px;
    }
    @media (max-width : @screen-sm-max){
        flex-wrap: wrap;

    }
    @media (max-width : @screen-vxs-max){
        justify-content: center;
    }
    .heading-text{
        margin-bottom: 20px;


        h1 {
            color: @blue-color;
            margin-bottom: 0px;
            line-height:1;
        }
        h2{

        }
        h6{
            margin-bottom: 0;
            span{
                color: @blue-color;
            }
        }
        small{
            font-size: 15px;
            font-family: @font-flama;
        }
    }
    .right-content{
        /* position: fixed;*/
        /*width: 247px;*/
        @media (max-width : @screen-xs-max){
            position: relative;
            padding-right: 0;
        }
        @media (max-width : @screen-xs-max){
            width: 50%;
        }
        @media (max-width : @screen-vxs-max){
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
        }
    }
    .right-sidebar-ad{
        position: fixed;

        display: block;
        margin-top: 50px;
        top: 60vh;
        /*height: 250px;*/
        @media (max-width : @screen-xs-max){
            position: static;
            width: 50%;
            margin-top: 0;
            text-align: center;
            padding: 4vh 5vw;
        }
        @media (max-width : @screen-vxs-max){
            width: 100%;
        }
    }
}
.partners {

    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: flex-start;
        margin: 0;

        @media (max-width : @screen-sm){

            //flex-direction: column;
        }
    }

    .partner-block {
        max-width: 30%;
        margin-left: 1%;
        margin-right: 1%;
        padding: 10px;

        text-align: center;
        .desc {
            a {

            }
            p {
                font-size: 1.8rem;
            }
        }


        @media (max-width: @screen-md) {
            max-width: 40%;
        }
        @media (max-width : @screen-sm){

            max-width: 45%;
        }


    }
}

/* event */
.page_header{
    margin-bottom: 20px;
    .contain{
        position: relative;
        /*background-image: none !important;*/
        background-size: cover;
        background-position: center;
        @media (min-width : @screen-lg-min){
            height: 40vh;
            min-height: 250px;
            img{
                display: none;
            }
        }

        .banner-caption{
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            transform: translate(0,-50%);
            text-align: center;
            color: #fff;
            background-color: rgba(17, 17, 17, 0.5);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            *{
                color: #fff;
            }
            h1{
                margin-bottom: 0;
            }
        }
    }
    form{
        font-family: @font-flama;
        font-size: @font-size-large;
        margin-top: 10px;
        input{
            background: transparent;
            border: none;
            border-bottom: 2px solid white;
            margin: 0;
            margin-bottom: 2px;
            padding: 0;
            border-radius: 0;
            color: #fff;
            padding: 5px 5px;
            display: inline-block;
            width: auto;
            font-weight: 400;
            .placeholder-color(#fff);
            height: 35px;
            margin:0 5px 10px;
        }
        input[type="submit"]{
            margin: 0;
            border: 1px solid #fff;
            padding: 7px 15px;
        }
    }
}
.event-list-2-columns{
    .grid{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    .col{
        display: inline-flex;
        flex-direction: column;
        padding: 20px;
        width: 50%;
        margin-bottom: 20px;
        @media (max-width : @screen-sm-max){
            max-width: 100%;
            width: 100%;
        }
        .btn{
            margin: 5px;
        }
    }
}
.title{
    @media (max-width : @screen-xs-max){
        // text-align: center;
    }
}
/* POST LIST PAGE TEMPLATE */

.featured-post-sec{
    @media (max-width : @screen-xs-max){
        //  text-align: center;
    }
    margin-bottom: 0;
    .post-img{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        min-height: 400px;
        max-height: 35vh;

        @media (max-width: @screen-xs){
            min-height: 250px;

        }
    }
    .post-text{
        @media (min-width : @screen-x-lg-min){
            padding: 0 20px;
        }
        p {
            font-size: 110%;
        }
        .btn.btn-default {
            margin: 0;
            margin-top: 2vh;

        }
    }
}
.category-filters{
    > p{
        font-family: @font-flama;
        text-align: center;
        margin: 4% auto;
        font-weight: 300;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 10px;
        a{
            color: @gray-base;
            margin: 0 5%;
            &:hover{
                border-bottom:2px solid @gray-base;
            }
            @media (min-width : @screen-md) and (max-width : @screen-lg){
                margin: 0 4%;
            }
            @media (max-width : @screen-sm-max){
                margin: 0 3%;
            }
            @media (max-width : @screen-vxs-max){
                margin: 0 1.8%;
            }
        }
        @media (max-width : @screen-sm-max){
            font-size: 1.8rem;
        }
        @media (max-width : @screen-vxs-max){
            font-size: 15px;
        }
    }
}
.divider{
    text-align: center;
    font-family: @font-flama;
    width: 100%;
    position: relative;
    margin: 0 auto;
    .line{
        position: absolute;
        background: #ccc;
        height: .1em;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
    }
    h4{
        position: relative;
        display: inline-block;
        padding: 2.5vw 3vw;
        margin-bottom: 0;
        background-color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }
}
.grid-list {
    padding:  0;
    @media (max-width : @screen-xs-max){
        //  text-align: center;
    }
    @media (max-width : @screen-vxs-max){
        justify-content: center;
    }
    .item{
        margin-bottom: 20px;
        display: inline-block;
        vertical-align: top;
        &:hover{
            .roll{
                background: rgba(31, 31, 31, 0.7);
                justify-content: center;
                align-items: center;
                i{
                    font-size: 25px;
                }
                p{
                    opacity: 1;
                    display: block;

                }
            }
        }
        @media (max-width: 1100px) {
            margin-top: 2vh;
        }

        @media (max-width : @screen-vxs-max){
            max-width: 90vw;
            padding: 0;
            margin-top: 4vh;
        }
    }
    a{
        display: block;
    }
    .image{

        min-height: 130px;
        margin-bottom: 10px;
        position: relative;
        background-size: cover;
        background-position: center;

        @media (min-width: @screen-lg){
            min-height: 200px;
        }
        @media (max-width: @screen-md){
           min-height: 300px;
        }
        @media (max-width: @screen-xs){
            min-height: 200px;

        }


        @media (max-width : @screen-vxs-max){
            //height: 25vh;
        }

        .roll{
            color: #fff;
            height: 100%;
            min-height: 130px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;
            padding: 10px;
            i{
                .transition(0.4s);
            }
            p{
                margin: 0;
                color: inherit;
                opacity: 0;
                transition: .3s opacity ease;
                display: none;
                text-align: center;
                .transition(0.4s);
            }
            @media (min-width: @screen-lg){
                min-height: 200px;
            }
            @media (max-width: @screen-md){
                min-height: 300px;

            }
            @media (max-width: @screen-xs){
                min-height: 200px;

            }
        }

    }
    .title{
        font-family: @font-flama;
        color: #1F1F1F;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: .5em;
        font-weight: 600;
        font-size: 15px;
    }
    .text{
        margin-bottom: 1.5vh;

        p {margin: .3rem 0}

        .blurb{
            font-family:@font-flama;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            p{
                font-size: 18px;
                margin-bottom: 0;
            }
        }
    }
    .details{
        .time {
            font-size: 1.5rem;
            color: rgba(31, 31, 31, 0.8);
        }
    }
    .slick-arrow{
        display: none !important;
    }
    &.slick-slider{
        margin-left: -10px;
        margin-right: -10px;
    }
}

.info{
    margin-bottom: 20px;
    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width : @screen-vxs-max){
            flex-wrap: wrap;

        }
    }
    .first{
        display: inline-flex;
        vertical-align: top;
        p{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.blog-detail{
    .title{
        margin-bottom: 20px;
        h1{
            margin-bottom: 0;
        }
        p{margin-top: 0;}
    }
    .header{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        overflow: hidden;
        height: 40vh;
        min-height: 300px;
        img{
            display: none;
        }
        &.video-section{
            height: auto;
            min-height: 10px;
            /*.video-iframe{
                padding-top: 56.25%;
                position: relative;
                iframe{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }*/
        }
    }
}
.social-info{
    margin-top: 20px;
}

/* POST DETAIL */
.post-detail{
    max-width: 900px;
    .featured-img {
        margin-bottom: 20px;
        min-height: 400px;
        max-height: 40vh;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        @media (max-width : @screen-sm-max){
            height: 35vh;
            min-height: 250px;
        }

    }
    .title{
        margin-bottom: 20px;
        h1{
            margin-bottom: 0;
        }
        .p-large {
            font-family: @font-flamaCondensed;
            font-size: 15px;
            i{
                font-style: normal;
            }
        }
    }
    .post{
        max-width: 800px;
        .post_share {
            margin-top: 4rem;
        }
        .about-author{
            border-top: 1px solid @black-bg;
            margin-top: 4rem;
            padding-top: 1em;
        }
    }
}
/* search result */
.search-results{
    .search-blocks + .search-blocks{
        border-top: 1px dotted;
        margin-bottom: 10px;
        /*padding-top: 10px;*/
    }
    .search-blocks{
        padding: 10px 0;
        p a{
            font-weight: 600;
            color: @blue-color;
            font-family: @font-flamaCondensed;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
.cart-count{
    font-size: 15px;
}
.simple_search_form{
    form{
        display: flex;
        .txt-box{
            flex: 1 0 0%;
            margin: 0;
            margin-right: 1.2vw;
        }
        .btn{
            width: 30%;
            max-width: 300px;
            margin: 0;
        }
    }
}
.at-resp-share-element .at-share-btn{
    margin-bottom: 0;
}
.grid-list.slick-slider .item{
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
}
.heading-title{
    margin-bottom: 10px;
}
.detail-content{
    width: 800px;
    @media (max-width : @screen-sm-max){
        width: auto;
    }
    h2{
        text-transform: uppercase;
    }
    .image{
        width: 250px;
        display: inline-block;
        vertical-align: top;
        @media (max-width : @screen-xs-max){
            display: block;
            width: auto;
        }
    }
    .desc{
        width: calc(~"100% - 264px");
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;

        @media (max-width : @screen-xs-max){
            display: block;
            width: 100%;
        }
        a{
            color: @blue-color;
        }
    }
}
.fancybox-content{
    max-width: 90% !important;
}
.heading-title2{
    align-items: center;
    a{
        color: @blue-color;
        font-weight: 600;
        font-size: 14px;
        &:hover{
            text-decoration: underline;

        }
    }
}
/* mini laptop resolution 1349 X 662 and 1246 X 681 and 1218 X 672 */
@media (min-width: 1100px) and (max-height: 750px) {
    //html, body{font-size: 80%;}
    //header .navigation{margin-top: 22vh;}
}

/* ACCOUNT PAGE  /  INSIDER*/
.live-event-banner {
    margin: 30px 0;
}
