//footer

.main-content{
    &:not(.full-container){
        .footer{
            display: none;
            @media (max-width : @screen-xs-max){
             display: flex;
                padding: 4vw 5vw;                
            }
            @media (max-width : @screen-xs-max){
                justify-content: center;
                text-align: center;                
            }
        }    
    }
    .inner-wrap{
        .footer{
            display: flex;
            @media (max-width : @screen-xs-max){
                display: none;                
            }
        }    
    }
}
.footer{
    padding: 20px 0;
}
.footer-nav {
    display: inline-flex;
    margin-left: 0;
    margin-bottom: 0;
    flex-wrap: wrap;
    @media (max-width : @screen-vxs-max){
        margin-right: 0;
    }
    li{
        list-style: none;
        margin-right: 0;    
        a{
            position: relative;
            color: @gray-base;
            &:after{
                content: "|";
                display: inline-block;
                margin: 0 5px;
            }   
            &:hover{
                color: @blue-color;
            }
        }
        &:last-child{
            a{
                &:after{
                    display: none;
                }
            }
        }
    }
}
.footer-social{
    display: flex;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    li {
        list-style: none;
        a{
            font-size: 50px;
            width: 1em;
            height: 1em;
            display: block;
            position: relative;
            img{
                vertical-align: top;
            }
            &:hover{
                opacity: 0.5;
            }
        }
    }
}