//== header
header{
    min-width: 200px;
    width: 20%;
    position:relative;
    height: 100vh;    
    display: inline-block;   
    z-index: 9999;
    @media (max-width: 1100px){
        width: 8vw;
        min-width: 10px;        
    }   
    @media (max-width : @screen-xs-max){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        padding: 3vh 5vw;
        height: auto;
        //.transition(0.4s);
        height: 70px;
    }
    &.is-scroll{         
        @media (max-width : @screen-xs-max){
            padding: 2vh 5vw;
            box-shadow: 0px 3px 18px 2px rgba(0, 0, 0, 0.35);
        }
    }
    .inner-header{
        position: fixed;
        top: 0; 
        left: 0;
        width:inherit;
        height: 100%;
        padding: 3vh;
        display: flex;
        flex-direction: column;
        padding-bottom: 110px;
        background-color: #fff;        
        .transition(0.4s);
        /* Tablet */
        @media (max-width: 1100px){            
            /*width: 31vw;
            left: -21vw;*/
            padding-right: 70px;
            width: 250px;
            left: -180px;  
        }
        @media (max-width : @screen-xs-max){
            padding: 0;
            width: 100%;
            left: 0;
            position: static;
            justify-content: center;
            align-items: flex-start;
        }
        &.search-open{
            width: auto;
            z-index: 1;
            pointer-events: none;
            .navigation,
            .search-btn,
            .login-section{
                display: none;
            }
            .brand{
                pointer-events: auto;
                margin-bottom: 0;
            }
        }
    }
    &.is-open{
        .inner-header{            
            @media (min-width: @screen-sm-min) and (max-width: 1100px){
                left: 0;
                box-shadow: 0px 3px 18px 2px rgba(0, 0, 0, 0.35);

            }             
        }    
    }

    .brand{   
        position: fixed;        
        left: 1.6vw;
        top: 1.7vw;        
        min-width: 30px;
        margin-bottom: 0;
        display: inline-block;
        width: 4vw;
        min-width: 50px;
        /*margin-bottom: 8vh;*/
        @media (max-width : @screen-sm-max){
            left:1.4vw;    
        }        
        img {
            max-width:100%;
        }
        //#svg2{
        //    width: 100%;
        //    height: 100%;
        //    left: 0;
        //    top: 0;
        //    position: absolute;
        //    path {
        //        max-width: 100%;
        //    }
        //}
        @media (max-width: 1100px){

        }
        @media (max-width : @screen-xs-max){
            position: relative;
            width: 7vh;
            top: auto;
            left: auto;
            z-index: 1;
        }
    }  
    .navigation{
        margin-top: 20vh;
        @media (max-width: 1100px){
            margin-top: 18vh;
        }
        @media (max-width : @screen-xs-max){
            margin-top: 0;
        }
        .hamburg-menu{
            display: none;
            @media (max-width: 1100px){
                //width: 4vw;
                width: 2.4vw;
                height: 40px;
                display: inline-block;
                //background-color: #000;
                position: absolute;
                right: 15px;
                transform: translateY(-100%);
                transition-duration: 0.5s;
                min-width: 36px;
                span{
                    transition-duration: 0.5s;
                    position: absolute;
                    height: 4px;
                    width: 100%;
                    top: 50%;
                    margin-top: -2px;
                    background-color: #212121;
                    &:before{
                        transition-duration: 0.5s;
                        position: absolute;
                        width: inherit;
                        height: 4px;
                        background-color: #212121;
                        content: "";
                        top: -11px;
                    }
                    &:after{
                        transition-duration: 0.5s;
                        position: absolute;
                        width: inherit;
                        height: 4px;
                        background-color: #212121;
                        content: "";
                        top: 11px;
                    }
                }
            }
            @media (max-width : @screen-xs-max){
                transform: translateY(-50%);
                top: 50%;
                right: 20px;
            }
            @media (max-width : @screen-vxs-max){
                width: 35px;
            }            
            &.is-open{                
                span{
                    background-color: rgba(0,0,0,.0);
                    &:before{
                        top: 0;
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        background:#212121;
                    }
                    &:after{
                        top: 0;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        background: #212121;
                    }
                    @media (max-width : @screen-vxs-max){
                        &:before{

                        }
                        &:after{

                        } 
                    }
                }
            }            
        }
        ul{
            margin: 0;
            position: relative;
            li{
                list-style: none;
                font-size: 2.6rem;
                font-weight: 300;
                font-family: @font-flama; 
                color: @gray-base;
                margin-bottom: .5vh;
                line-height: normal;
                a{
                    color: inherit;
                    display: inline-block;
                    line-height:1.25em;
                    &:hover{
                        text-decoration: none;
                        color: @blue-color;
                    }
                }
            }
            @media (max-width : @screen-xs-max){
                display: none;
            }
        }
        ul+ul{
            &:before{
                content: "";
                width:33px;
                height: 3px;
                background-color: #808080;
                display: block;
                margin-top: 18px;
                margin-bottom: 15px;
            }
        }

    }
    .login-section{
        align-items: flex-end;
        position: absolute;
        bottom: 0px;
        left: 0; 
        right: 0;
        padding: 3vh;
        p{
            line-height: 1.2;
            margin-bottom: 10px;
        }   
        .login-signup {
            font-family: @font-flamaSemiCondensed;
            a{
                color: @text-color;
                font-weight: bold;
                &:hover{
                    text-decoration: none; 
                    color: @blue-color;  
                }
            }
        }
        @media (max-width: 1100px){
            padding: 20px 70px 20px 20px;
        }
        @media (max-width : @screen-xs-max){
            display: none;
        }
    }
    .search-btn{
        position: relative;
        font-family: @font-flamaSemiCondensed;
        font-size: 1.8rem;
        font-weight: 600;
        color: @gray-base;
        &:before{
            content: "";
            width: 33px;
            height: 3px;
            background-color: #000;
            display: block;
            margin-top: 18px;
            margin-bottom: 15px;
        }
        a{
            pointer-events: visible !important; 
            color: @gray-base;
        }
        i{
            margin-left: 5px;

        }
        @media (max-width : @screen-xs-max){
            &:before{
                display: none;
            }
            font-size: 0;
            position: absolute;
            right: 75px;
            top: 25px;
            i{
                margin-left: 0;
                font-size: 25px;
            }
        }
    }
}
@media (min-width: 1101px){
    .main-content.full-container{
        header{
            width: 7vw;
            min-width: 10px;        
            .inner-header{
                /*width: 31vw;
                left: -21vw;*/
                padding-right: 70px;
                width: 300px;
                left: -230px;
            }
            &.is-open{
                .inner-header{
                    @media (min-width: @screen-sm-min){
                        left: 0;
                        box-shadow: 0px 3px 18px 2px rgba(0, 0, 0, 0.35);
                    }
                }
            }
            .brand{            
                /*position: fixed;                
                left: 27px;
                top: 1.5vw;     
                min-width: 30px;
                margin-bottom: 0; */
            }
            .navigation{            

                .hamburg-menu{               
                    //width: 4vw;
                    width: 2.4vw;
                    height: 40px;
                    display: inline-block;
                    //background-color: #000;
                    position: fixed;
                    /*right: -10px;*/
                    left: 2.3vw;
                    transform: translateY(-100%);
                    transition-duration: 0.4s;
                    cursor: pointer; 
                    &.is-open{
                        //position: absolute;
                        left: 240px;
                        //right: 15px;
                        span{
                            background-color: rgba(0,0,0,.0);
                            &:before{
                                top: 0;
                                transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                                background: #212121;
                            }
                            &:after{
                                top: 0;
                                transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                background: #212121;
                            }
                            @media (max-width : @screen-vxs-max){
                                &:before{
                                    -webkit-transform: rotateZ(-45deg) scaleX(0.75) translate(-11px, -3px);
                                    transform: rotateZ(-45deg) scaleX(0.75) translate(-11px, -3px);
                                }
                                &:after{
                                    -webkit-transform: rotateZ(45deg) scaleX(0.75) translate(-11px, 3px);
                                    transform: rotateZ(45deg) scaleX(0.75) translate(-11px, 3px);
                                } 
                            }
                        }
                    }
                    span{
                        transition-duration: 0.5s;
                        position: absolute;
                        height: 4px;
                        width: 100%;
                        top: 50%;
                        margin-top: -2px;
                        background-color: #212121;
                        &:before{
                            transition-duration: 0.5s;
                            position: absolute;
                            width: inherit;
                            height: 4px;
                            background-color: #212121;
                            content: "";
                            top: -0.7vw
                        }
                        &:after{
                            content: "";
                            width: inherit;
                            height: 4px;
                            position: absolute;
                            top: 0.7vw;
                            background-color: #212121;
                            transition-duration: 0.5s;
                        }
                    } 
                    /*span{
                    background-color: rgba(0,0,0,.0);
                    &:before{
                    top: 0;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    background: rgba(72, 72, 69, 0.85);
                }
                    &:after{
                    top: 0;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    background: rgba(72, 72, 69, 0.85);
                }
                } */
                }        
            }
            .login-section{            
                padding: 20px 70px 20px 20px;
            }        
        }
        .site-content{
            width: 100%;
            max-width: calc(~"100% - 14vw");
        }
    }
}
.inner-wrap .navigation{
    .account-nav,
    nav,
    .nav-more{
        display: none;
    }
}

header.is-open{
    #mobile-menu{
        left: 0;
        width: 100%;
    }
}
#mobile-menu{
    display: none;
    padding: 5vh 5vw;
    overflow: auto;    
    @media (max-width : @screen-xs-max){
        display: block;
        background-color: #fff;
        position: fixed;
        top: 70px;
        left: -100%;
        width: calc(~"100% - 67px");
        height: 100%;
        .transition(0.4s);        
        //box-shadow: -1px 0 7px 0 rgba(0,0,0,.75);
        height: calc(~"100% - 70px");       
    }
    ul{
        margin: 0;
        position: relative;
        li{
            list-style: none;
            font-size: 26px;
            font-weight: 300;
            font-family: @font-flama; 
            color: @gray-base;
            margin-bottom: 7px;
            @media (max-width : @screen-vxs-max){
                // font-size: 20px;
            }
            a{
                display: block;
                line-height: normal;
                color: @text-color !important;
            }
        }
    }
    .m-login-section{
        margin-top: 30px;
        p{
            margin-bottom: 0;
        }        
        .login-signup {
            font-family: @font-flamaSemiCondensed;
            a{
                color: @text-color;
                font-weight: bold;
                font-size: 14px;
                &:hover{
                    text-decoration: none; 
                    color: @blue-color;  
                }
            }
        }
    }
}
/* serch bar popup */
.search-popup{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    padding: 10vh 10vw;
    // .transition(0.4s);
    transform: scale(0);
    input{
        border: 0;        
        height: 10vh;
        font-size: 5vh;
        .placeholder-color (#ccc);
    }
    .form-col:first-of-type{
        border-bottom: 1px solid #ccc;
    }
    .submit-form-btn{
        display: none;
    }
    .close-btn{
        position: absolute;
        top: 3vh;
        right: 3vh;
        font-size: 26px;
        pointer-events: visible !important;
        color: @gray-base;        
    }
    &.is-open{
        opacity: 1;
        visibility: visible;
        transform: scale(1); 
    }
    .form{
        @media (max-width : @screen-xs-max){
            margin-top: 70px;
        }
    }
}

/*.main-content.full-container header .navigation .hamburg-menu span*/