*, *:before, *:after {
    .box-sizing();
} 
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, 
legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, a, nav, section, summary, time, mark, audio, video {
    margin:0; 
    padding:0; 
    border:0; 
    outline:0; 
    font-size:100%;
    vertical-align:baseline; 
    background:transparent; 
    text-decoration:none; 
    list-style:none; 
    outline:none; 
    -webkit-font-smoothing: subpixel-antialiased; 
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}

html,body { 
    -webkit-text-size-adjust:none; 
    height: 100%; 
    width: 100%;
}
article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
    display: block;
}
iframe {
    vertical-align: top;
}
input[type="text"], 
input[type="submit"], 
input[type="button"], 
input[type="password"], 
input[type="email"], 
input[type="tel"], 
input[type="search"], 
textarea {-webkit-appearance: none; outline: none;}
textarea:focus, input:focus, a, div, img { 
    outline: none; 
}


.cf,
.clearfix {
    .clearfix();
}

html, body {
    -webkit-text-size-adjust:none;
    -webkit-font-smoothing: antialiased;
    font-size: 62.5%;    
}
body {
    font-family: @font-family-sans-serif;
    font-size: @font-size-base;
    line-height: @line-height-base;
    font-weight: @font-weight-base;
    color: @text-color;
    margin: 0;
    padding: 0;
    background-color: @body-bg;
    @media (max-width: @screen-sm-max){
        font-size: @font-size-base - 0.2;
    }
    @media (max-width: @screen-xs-max){
        font-size: @font-size-base - 0.4;
    }
}

a{
    //.transition(0.4s);
    color: @link-color;
    &:hover,
    &:focus {
        color: @link-hover-color;
        //text-decoration: underline;
    }
}


/* POST TEXT */
p, ul li, ol li {
    font-size: @font-size-large;
    line-height: 1.5;
    /*letter-spacing: -.005rem;*/
    letter-spacing: -0.0025em;    
    color: rgba(0,0,0,.84);
    font-weight: 400;
    @media (max-width: @screen-xs-max){
        margin: 0 0 10px;
        font-size: 16px;
    }
}

p {
    /*margin: 2.35rem 0 0;*/
    margin: 0 0 1em 0;
    /*  &:last-child{
    margin-bottom: 15px;
}*/
    @media (max-width: @screen-xs-max){
        margin: 0 0 10px;
        font-size: 16px;
    }
}

img {
    max-width: 100%;
}

button:focus,
button:focus,
button:active,
button.current,
div:focus,
a:focus,
input:focus,
image:focus{
    outline: none;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: @headings-font-family;
    font-weight: @headings-font-weight; 
    line-height: @headings-line-height;
    color: @headings-color;
    margin: 0 0 .5em;
    @media (max-width: @screen-xs-max){
        margin: 0 0 10px;
    }
}

h1, .h1 {font-size: @font-size-h1; font-weight: @headings-font-weight + 100;}
h2, .h2 {font-size: @font-size-h2;}
h3, .h3 {font-size: @font-size-h3;}
h4, .h4 {font-size: @font-size-h4;}
h5, .h5 {font-size: @font-size-h5;}
h6, .h6 {font-size: @font-size-h6;}

@media (max-width: @screen-sm-max){
    h1, .h1 {font-size: @font-size-h1 - 0.8;}
    h2, .h2 {font-size: @font-size-h2 - 0.6;}
    h3, .h3 {font-size: @font-size-h3 - 0.4;}
    h4, .h4 {font-size: @font-size-h4 - 0.4;}
    h5, .h5 {font-size: @font-size-h5 - 0.4;}
    h6, .h6 {font-size: @font-size-h6 - 0.4;}
}

@media (max-width: @screen-xs-max){
    h1, .h1 {font-size: 30px;}
    h2, .h2 {font-size: 26px;}
    h3, .h3 {font-size: 22px;}
    h4, .h4 {font-size: 20px;}
    h5, .h5 {font-size: 18px;}
    h6, .h6 {font-size: 16px;}
}

ul, ol {
    margin: .25rem 3rem 2rem;
    padding: 0;
}
ul {
    li {
        padding-left: 0;
        position: relative;

    }
    > li {
        list-style:outside disc;
    }
}
ol {
    li {
        color: @text-color;
        padding-left: 0;
        position: relative;

    }
    > li {
        list-style: outside decimal;
    }
    ul, ol {
        margin: 10px 20px;
    }
}

blockquote {
    display: block;
    margin: 40px 0 30px;
    position: relative;
    padding: 30px 30px;
    border: 2px solid @text-color;
    text-align: center;  
    @media (max-width: @screen-sm-max){
        padding: 20px;
    }
    @media (max-width: @screen-xs-max){
        padding: 20px 15px 20px;
    }
    p {
        font-size: @font-size-base;
        line-height: 1.2;
        color: @text-color;
        @media (max-width: @screen-sm-max){
            font-size: @font-size-base - 2;
        }
        @media (max-width: @screen-xs-max){
            font-size: @font-size-base - 4;
        }
    }
}

hr {
    width: 100%;
    border: 0 solid @light-gray; 
    border-top-width: 1px;
    clear: both;
    height: 0;
    margin: 15px auto;
}

img {
    border: none;
    border-style: none;
    vertical-align: top;
    height: auto;
    max-width: 100%;
}

.aligncenter {
    display: block;
    clear: both;
    margin: 30px auto;
    @media (max-width: @screen-xs-max) {
        margin: 20px auto;
    }
}

.alignleft {
    float: left;
    margin: 30px 30px 30px 0;
    @media (max-width: @screen-xs-max) {
        margin: 20px auto;
        float: none;
        display: block;
    }
}

.alignright {
    float: right;
    margin: 30px 0 30px 30px;
    @media (max-width: @screen-xs-max) {
        margin: 20px auto;
        float: none;
        display: block;
    }
}

.dis-table {
    display: table;
    height: 100%;
    width: 100%;
}

.dis-table-cell {
    display: table-cell;
    vertical-align: middle
}

.container {
    max-width: @container-width + 30;
    padding: 0 15px;
    max-width: 100%; 
    margin: 0 auto;
}

.container-full {
    max-width: none;
}


#wrapper {
    width: 100%;
    min-height: 100%;
    position: relative;
    background-color: @body-bg;
    z-index: 8;
    @media (max-width: @screen-xs-max) {
        box-shadow: 0 -14px 10px #000000;
    }
}

.btn {
    .transition(0.4s);     
    font-size: @font-size-base;
    font-family: @font-flamaSemiCondensed;
    font-weight: 800;       
    color: @blue-color;
    height: auto;
    text-align: center;    
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    padding: 1em ;
    position: relative;
    border: 2px solid @blue-color;
    background-color: transparent;
    border-radius: 0;
    text-transform: uppercase ;
    &:hover{
        text-decoration: none;
        background-color: @blue-color;
        color: #fff;
    }
    @media (max-width: @screen-xs-max) {
        font-size: 15px;
        padding: 1em;
    } 
    &.btn-flat{
        font-size: @font-size-base - 0.2;
        padding: 10px 15px;
    }
    &.btn-default {
        font-size: @font-size-base - 0.2;
        font-weight: 400;
        padding: 10px 15px;
        border-width: 1px;
        margin: 5px;
    }
    &.btn-reverse {
        background: @blue-color;
        color: white;
        &:hover {
            background: fade(@blue-color, 80%)
        }
    }
    &.login{
        width: 100%;
    }
    &.fb-login-btn{
        border-color:#3B5992; 
        background-color: #fff;
        color: #3B5992;
        width: 100%;

    }
}

label { 
    display: block;
    /*color: @label-color;
    font-weight: @label-font-weight;
    font-family: @label-font-family;
    font-size: @label-font-size;*/
    line-height: 1.2;
    width: 100%;
    margin: 0 0 7px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="date"],
textarea {
    -webkit-appearance: none;
    outline: none;
    .placeholder-color (@input-color-placeholder);   
    border: none;
    padding: 0 15px;
    font-family: @input-font-family;
    width: 100%;
    border-radius: 0;
    border: none;
    background-image: none;
    display: block;
    border: 1px solid @light-gray;
    margin: 0 0 10px;
    padding: 10px 15px;
    font-size: 15px;
    color: @input-color;    
    margin-bottom: 0;
    margin-bottom: 10px; 
    border-radius: 0;
    &:disabled {
        background-color: @input-bg-disabled;
    }    
}
textarea {
    padding: 30px 15px;
    resize: none;
    //  height: @textarea-height-base;
    height: 150px;
} 
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    width: auto;
    display: block;
    &:extend(.btn all);
    &:extend(.btn-default all);
}

button:focus,
button:focus,
button:active,
button.current,
div:focus,
a:focus,
input:foc us,
image:focus{
    outline: none;
}

select:-moz-focusring,
select::-moz-focus-inner {
    color: transparent !important;
    background-image: none !important;
    text-shadow: 0 0 0 #1a1a1a !important;
    border:0;
    border-color: inherit;
    -webkit-box-shadow: none;
}

select {    
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: @input-color;
    background-color: #fff;
    background-image: none;
    border: 1px solid @light-gray;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    margin: 0 0 10px;

    &:disabled {
        //background-color: @input-bg-disabled;
    }
}

select:focus {
    outline: none;
}

//== CheckBox RadioBox
.checkbox-custom {
    position: relative; 
    cursor: pointer; 
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        margin: 0;
        z-index: -1;
        width: 0;
        height: 0;
        overflow: hidden;
        left: 0;
        pointer-events: none;
        &:checked + .checkbox-material .check::before {
            display: block;
        }
    }
    .checkbox-material {
        vertical-align: top;
        position: relative;
        margin-right: 10px;
        line-height: 1;
        .check {
            .square(16px);
            display: inline-block;
            vertical-align: middle;
            position: relative;
            text-indent: -99999px;
            overflow: hidden;
            background-repeat: no-repeat;
            background-color: #fff;
            &:before {
                content: "";
                .all-pos-zero;
                display: none;
                margin: auto;
                position: absolute;
                //background-color: @theme-color;
                background-image: url('images/check-mark.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 10px auto;
            }
        } 
    }
}
.radiobox-custom {
    position: relative; 
    cursor: pointer; 
    input[type="radio"] {
        opacity: 0;
        position: absolute;
        margin: 0;
        z-index: -1;
        width: 0;
        height: 0;
        overflow: hidden;
        left: 0;
        pointer-events: none;
        &:checked + .radiobox-material .check::before {
            display: block;
        }
    }
    .radiobox-material {
        vertical-align: top;
        position: relative;
        margin-right: 10px;
        line-height: 1;
        .check {
            border-radius: 100%;
            .square(16px);
            display: inline-block;
            vertical-align: middle;
            position: relative;
            text-indent: -99999px;
            overflow: hidden;
            background-repeat: no-repeat;
            background-color: #fff;
            &:before {
                content: "";
                .all-pos-zero;
                .square(8px);
                display: none;
                margin: auto;
                position: absolute;
                //background-color: @theme-color;
                border-radius: 100%;
                background-image: none;
            }
        }
    }
}
.scroll-hidden{
    overflow: hidden;
    height: 100%;
    position: fixed;    
}
//== pagination
.pagination-wrapper{
    border-top: 1px solid #ccc;
    .pagination {
        display: inline-flex;
        padding-left: 0;
        margin: 20px 0;
        border-radius: 4px;        
        > li {
            margin: 0;
            letter-spacing: normal;
            display: inline-flex;
            vertical-align: top;
            @media (max-width : @screen-xs-max){
                font-size: 15px;
            }
            > a {                
                border-color:  @gray-base;
                color: @gray-base;
                position: relative;                
                padding: 6px 12px;
                border: 1px solid @gray-base;
                font-weight: 400;
                margin-left: -1px;
                line-height: normal;

            }
            &.active{
                a{
                    color: #fff;
                    background-color:@gray-base;
                }
            }
            &:hover:not(.active){
                a{
                    background-color: #787878;
                    color: #fff;
                }
            } 

        }
    }
}
.text-center{
    text-align: center;
}
/* */

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    @media (max-width: @screen-xs-max){
        margin-left: 0;
        margin-right: 0;
    }
}
[class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
}
.no-gutters {margin-right: 0;
    margin-left: 0;
}
.no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

/* flex box */
.d-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center ;
}
.align-content-center {
    -ms-flex-line-pack: center;
    align-content: center;
}
.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.justify-content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.justify-content-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

/* column structure */
/* ----- Extra small devices ≤640px ----- */
.col-12 {width: 100%;}
.col-11 {width: 91.66666667%;}
.col-10 {width: 83.33333333%;}
.col-9 {width: 75%;}
.col-8 {width: 66.66666667%;}
.col-7 {width: 58.33333333%;}
.col-6 {width: 50%;}
.col-5 {width: 41.66666667%;}
.col-4 {width: 33.33333333%;}
.col-3 {width: 25%;}
.col-2 {width: 16.66666667%;}
.col-1 {width: 8.33333333%;}
.col-offset-12 {margin-left: 100%;}
.col-offset-11 {margin-left: 91.66666667%;}
.col-offset-10 {margin-left: 83.33333333%;}
.col-offset-9 {margin-left: 75%;}
.col-offset-8 {margin-left: 66.66666667%;}
.col-offset-7 {margin-left: 58.33333333%;}
.col-offset-6 {margin-left: 50%;}
.col-offset-5 {margin-left: 41.66666667%;}
.col-offset-4 {margin-left: 33.33333333%;}
.col-offset-3 {margin-left: 25%;}
.col-offset-2 {margin-left: 16.66666667%;}
.col-offset-1 {margin-left: 8.33333333%;}
.col-offset-0 {margin-left: 0;}

/* ----- Small devices ≥641px ----- */
@media (min-width:641px) {
    .col-sm-12 {width: 100%;}
    .col-sm-11 {width: 91.66666667%;}
    .col-sm-10 {width: 83.33333333%;}
    .col-sm-9 {width: 75%;}
    .col-sm-8 {width: 66.66666667%;}
    .col-sm-7 {width: 58.33333333%;}
    .col-sm-6 {width: 50%;}
    .col-sm-5 {width: 41.66666667%;}
    .col-sm-4 {width: 33.33333333%;}
    .col-sm-3 {width: 25%;}
    .col-sm-2 {width: 16.66666667%;}
    .col-sm-1 {width: 8.33333333%;}
    .col-sm-offset-12 {margin-left: 100%;}
    .col-sm-offset-11 {margin-left: 91.66666667%;}
    .col-sm-offset-10 {margin-left: 83.33333333%;}
    .col-sm-offset-9 {margin-left: 75%;}
    .col-sm-offset-8 {margin-left: 66.66666667%;}
    .col-sm-offset-7 {margin-left: 58.33333333%;}
    .col-sm-offset-6 {margin-left: 50%;}
    .col-sm-offset-5 {margin-left: 41.66666667%;}
    .col-sm-offset-4 {margin-left: 33.33333333%;}
    .col-sm-offset-3 {margin-left: 25%;}
    .col-sm-offset-2 {margin-left: 16.66666667%;}
    .col-sm-offset-1 {margin-left: 8.33333333%;}
    .col-sm-offset-0 {margin-left: 0;}
}

/* ----- Medium devices ≥768px ----- */
@media (min-width:768px) {
    .col-md-12 {width: 100%;}
    .col-md-11 {width: 91.66666667%;}
    .col-md-10 {width: 83.33333333%;}
    .col-md-9 {width: 75%;}
    .col-md-8 {width: 66.66666667%;}
    .col-md-7 {width: 58.33333333%;}
    .col-md-6 {width: 50%;}
    .col-md-5 {width: 41.66666667%;}
    .col-md-4 {width: 33.33333333%;}
    .col-md-3 {width: 25%;}
    .col-md-2 {width: 16.66666667%;}
    .col-md-1 {width: 8.33333333%;}
    .col-md-offset-12 {margin-left: 100%;}
    .col-md-offset-11 {margin-left: 91.66666667%;}
    .col-md-offset-10 {margin-left: 83.33333333%;}
    .col-md-offset-9 {margin-left: 75%;}
    .col-md-offset-8 {margin-left: 66.66666667%;}
    .col-md-offset-7 {margin-left: 58.33333333%;}
    .col-md-offset-6 {margin-left: 50%;}
    .col-md-offset-5 {margin-left: 41.66666667%;}
    .col-md-offset-4 {margin-left: 33.33333333%;}
    .col-md-offset-3 {margin-left: 25%;}
    .col-md-offset-2 {margin-left: 16.66666667%;}
    .col-md-offset-1 {margin-left: 8.33333333%;}
    .col-md-offset-0 {margin-left: 0;}
}

/* ----- Large devices ≥1024px ----- */
@media (min-width:1024px) {
    .col-lg-12 {width: 100%}
    .col-lg-11 {width: 91.66666667%}
    .col-lg-10 {width: 83.33333333%}
    .col-lg-9 {width: 75%}
    .col-lg-8 {width: 66.66666667%}
    .col-lg-7 {width: 58.33333333%}
    .col-lg-6 {width: 50%}
    .col-lg-5 {width: 41.66666667%}
    .col-lg-4 {width: 33.33333333%}
    .col-lg-3 {width: 25%}
    .col-lg-2 {width: 16.66666667%}
    .col-lg-1 {width: 8.33333333%}
    .col-lg-offset-12 {margin-left: 100%}
    .col-lg-offset-11 {margin-left: 91.66666667%}
    .col-lg-offset-10 {margin-left: 83.33333333%}
    .col-lg-offset-9 {margin-left: 75%}
    .col-lg-offset-8 {margin-left: 66.66666667%}
    .col-lg-offset-7 {margin-left: 58.33333333%}
    .col-lg-offset-6 {margin-left: 50%}
    .col-lg-offset-5 {margin-left: 41.66666667%}
    .col-lg-offset-4 {margin-left: 33.33333333%}
    .col-lg-offset-3 {margin-left: 25%}
    .col-lg-offset-2 {margin-left: 16.66666667%}
    .col-lg-offset-1 {margin-left: 8.33333333%}
    .col-lg-offset-0 {margin-left: 0}
}

/* ----- Extra Large devices ≥1200px ----- */
@media (min-width:1280px) {
    .col-xl-12 {width: 100%;}
    .col-xl-11 {width: 91.66666667%;}
    .col-xl-10 {width: 83.33333333%;}
    .col-xl-9 {width: 75%;}
    .col-xl-8 {width: 66.66666667%;}
    .col-xl-7 {width: 58.33333333%;}
    .col-xl-6 {width: 50%;}
    .col-xl-5 {width: 41.66666667%;}
    .col-xl-4 {width: 33.33333333%;}
    .col-xl-3 {width: 25%;}
    .col-xl-2 {width: 16.66666667%;}
    .col-xl-1 {width: 8.33333333%;}
    .col-xl-offset-12 {margin-left: 100%;}
    .col-xl-offset-11 {margin-left: 91.66666667%;}
    .col-xl-offset-10 {margin-left: 83.33333333%;}
    .col-xl-offset-9 {margin-left: 75%;}
    .col-xl-offset-8 {margin-left: 66.66666667%;}
    .col-xl-offset-7 {margin-left: 58.33333333%;}
    .col-xl-offset-6 {margin-left: 50%;}
    .col-xl-offset-5 {margin-left: 41.66666667%;}
    .col-xl-offset-4 {margin-left: 33.33333333%;}
    .col-xl-offset-3 {margin-left: 25%;}
    .col-xl-offset-2 {margin-left: 16.66666667%;}
    .col-xl-offset-1 {margin-left: 8.33333333%;}
    .col-xl-offset-0 {margin-left: 0;}
}

/* ----- Extra Large devices ≥1600px ----- */
@media (min-width:1600px) {
    .col-xxl-12 {width: 100%;}
    .col-xxl-11 {width: 91.66666667%;}
    .col-xxl-10 {width: 83.33333333%;}
    .col-xxl-9 {width: 75%;}
    .col-xxl-8 {width: 66.66666667%;}
    .col-xxl-7 {width: 58.33333333%;}
    .col-xxl-6 {width: 50%;}
    .col-xxl-5 {width: 41.66666667%;}
    .col-xxl-4 {width: 33.33333333%;}
    .col-xxl-3 {width: 25%;}
    .col-xxl-2 {width: 16.66666667%;}
    .col-xxl-1 {width: 8.33333333%;}
    .col-xxl-offset-12 {margin-left: 100%;}
    .col-xxl-offset-11 {margin-left: 91.66666667%;}
    .col-xxl-offset-10 {margin-left: 83.33333333%;}
    .col-xxl-offset-9 {margin-left: 75%;}
    .col-xxl-offset-8 {margin-left: 66.66666667%;}
    .col-xxl-offset-7 {margin-left: 58.33333333%;}
    .col-xxl-offset-6 {margin-left: 50%;}
    .col-xxl-offset-5 {margin-left: 41.66666667%;}
    .col-xxl-offset-4 {margin-left: 33.33333333%;}
    .col-xxl-offset-3 {margin-left: 25%;}
    .col-xxl-offset-2 {margin-left: 16.66666667%;}
    .col-xxl-offset-1 {margin-left: 8.33333333%;}
    .col-xxl-offset-0 {margin-left: 0;}
}
/* hidden element */
.hidden{ display: none;}

/* ----- Extra small devices ≤640px ----- */
@media (max-width: 640px) {
    .hidden-xs-down {display: none;}
}
/* ----- Small devices ≥641px ----- */
@media (min-width: 641px) {
    .hidden-sm-up {display: none;}
}
/* ----- Small devices ≤767px ----- */
@media (max-width: 767px) {
    .hidden-sm-down {display: none;}
}
/* ----- Medium devices ≥768px ----- */
@media (min-width: 768px) {
    .hidden-md-up {display: none;}
}
/* ----- Medium devices ≤1024px ----- */
@media (max-width: 1023px) {
    .hidden-md-down {display: none;}
}
/* ----- Large devices ≥1024px ----- */
@media (min-width: 1024px) {
    .hidden-xl-up {display: none;}
}

/* Wordpress align class */
.alignnone{float: none; margin: 20px 0;}
.aligncenter {display: block; clear:both; margin:20px auto;}
.alignleft {float: left; margin: 0 20px 20px 0; }
.alignright {float: right; margin: 0 0 20px 20px;}

/* text alignment */
.text-center{text-align: center;}
.text-right{text-align: right;}
.text-left{text-align: left;}
.text-justify{text-align: justify;}

/* images */
.img-fluid {max-width: 100%;height: auto;display: block;}
.img-full{width: 100%;display: block;}

/* float class */
.float-left{float:left}
.float-right{float:right}
.float-none{float:none}

/* mange spacing */
.space-small{ padding-top: 30px; padding-bottom: 30px; }
.space-medium { padding-top: 50px; padding-bottom: 50px; }
.space-large { padding-top: 70px; padding-bottom: 70px; }
.no-bdr{border-width: 0 }

/* padding */
.no-padding{padding:0;}
.pt-0{padding-top:0;}
.pb-0{padding-bottom:0;}
.pl-0{padding-left:0;}
.pr-0{padding-right:0;}
.pt-1{padding-top:20px;}
.pb-1{padding-bottom:20px;}
.pl-1{padding-left:20px;}
.pr-1{padding-right:20px;}

/* margin */
.no-margin{margin:0;}
.mt-0{margin-top:0;}
.mb-0{margin-bottom:0;}
.ml-0{margin-left:0;}
.mr-0{margin-right:0;}
.mt-1{margin-top:20px;}
.mb-1{margin-bottom:20px;}
.ml-1{margin-left:20px;}
.mr-1{margin-right:20px;}